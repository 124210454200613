<template>
  <div class="TabButtonsWrapper col-lg-12">
    <div class="gridContainer">
      <template v-for="(value, idx) in points" >
        <div v-bind:key="idx" class="gridItems" :class="{'activeTab' : activeIdx === idx}">
          <p v-bind:key="idx" v-html="value" @click="setAsActive(idx)" :class="{'firstChild' : idx === 0}"></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIdx: 0
    }
  },
  mounted () {
    this.activeIdx = this.selectedIdx;
  },
  watch: {
    activeIdx () {
      this.$emit('input' , this.activeIdx)
    }
  },
  props: {
    points: {
      type: Array,
      default: () => []
    },
    selectedIdx: {
      type: Number,
      default: 0
    }
  },
  methods: {
    setAsActive (selectedIdx) {
      this.activeIdx = selectedIdx
    }
  }
}
</script>

<style lang="scss">

.TabButtonsWrapper {
  padding-bottom: 20px;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #8CC6FF;
  border-radius: 8px;

  @media (max-width: 992px) {
    grid-template-columns: auto;
  }
}

.gridItems {
  padding: 10px ;
  text-align: center;
  font: normal normal 300 16px/22px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: #1B1B1B;
  opacity: 0.5;

  p {
    margin: 0px;
    padding: 10px;
    border-left : 1px solid #707070;

    @media (max-width: 992px) {
      border-top : 1px solid #707070;
    }
  }

  .firstChild {
    border-left:none;
    border-top: none;
  }

  &:nth-child(1) {
    padding-right: 0px;

    @media (max-width: 992px) { padding-bottom: 0px; }
  }
  &:nth-child(2) {
    padding-right: 0px;
    padding-left: 0px;
    @media (max-width: 992px) { padding-bottom: 0px; padding-top: 0px; }
  }
  &:nth-child(3) {
    padding-left: 0px;
    @media (max-width: 992px) { padding-top: 0px; }
  }
  cursor: pointer;
}

.activeTab {
  font: normal normal 600 16px/19px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: #FBFBFB;
  opacity: 1;

  background: #0D2694 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

</style>