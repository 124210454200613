<template>
  <div
    v-if="list"
    class="barcode-scanner__products-list"
    :style="styles"
  >
    <div
      class="barcode-scanner__products-list_item d-flex"
      style="width: 100%"
    >
      <div class="col-lg-10 my-2 col-10 d-flex">
        <img
          src="@/assets/images/svg/pglogo.svg"
          alt=""
          width="60px"
          class="mr-2"
        >
        <div class="item-content d-flex">
          <div class="name">
            {{ list.name }}
          </div>
          <div class="ean">
            EAN: {{ list.ean }}
          </div>
        </div>
      </div>
      <div class="col-lg-2 my-2 p-0 col-2">
        <b-input-group>
          <template #append>
            <b-button
              size="sm"
              @click="resetProducts()"
              variant="danger"
            >
              <b-icon-trash icon="trash-fill" />
            </b-button>
          </template>
        </b-input-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { BIconTrash } from 'bootstrap-vue'

export default {
  name: 'ProductsList',
  components: {
    BIconTrash
  },
  props: {
    styles: {
      type: Object,
      default: () => {}
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showReturnBackButton: {
      type: Boolean,
      default: false
    },
    maxProductCount: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts
    })
  },
  methods: {
    ...mapMutations(["resetProducts"])
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner__products-list {
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #B4A4E5;
  box-sizing: border-box;
  border-radius: 16px;

  @media (max-width: 767px) {
    padding: 0;
  }
  &_item {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    div {
      align-items: center;
    }
    .item-content {
      flex-direction: column;
      padding-left: 10px;
      align-items: flex-start;

      letter-spacing: 0px;
      color: #000000;


      .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px;
      }

      .ean {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  .circle {
    display: flex;
    width: 44px;
    height: 44px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #9ADFE6;
    border-radius: 2em;
  }
}

.select-none {
  user-select: none;
}

.short-logo {
  width: 36px;
}

.btn-info {
  width: 44px;
  height: 44px;
  border-radius: 100% !important;
  font-size: 0;
  line-height: 1;
  background-color: var(--color9);
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }

  .input-group-prepend &{
    background-image: url("../../assets/icons/minus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .input-group-append &{
    background-image: url("../../assets/icons/plus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-danger {
  background: 0;
  border: 0;
  border-radius: 0;
  color: #6F6F6F;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
}
.btn-edit {
  background: 0;
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
  img {
    width: 70%;
  }
}

.input-group {
  justify-content: flex-end;
  .form-control {
    border: 0;
    padding: 0;
    margin: 0 5px;
    text-align: center;
    flex: 0 1 26%;
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
