import store from '../store'

export default {
  methods: {
    goToPage: (pageName, self) => {
      self.$eventHub.$emit('goToPage-event', pageName);
    },
    goNextStep: (name, self) => {
      store.state.currentSection = name
      self.scrollTo("#" + name)
    },
    sendProductPhotos:(params,buttonComponent)=>{
      let fieldId = params;
      // console.log("1. will SEND products:::", fieldId, buttonComponent)

      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });

      buttonComponent.$eventHub.$emit('sendProductPhotos-event', fieldId);
    },
    sendForm: async (params, buttonComponent) => {
      if (params.indexOf(',') > -1) {
        params = params.split(',')
      }
      let formId = params;
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });

      await buttonComponent.$eventHub.$emit('getFormData-event', formId);
    },
    sendSms: (component, buttonComponent) => {
      // console.log("1. will SEND SMS:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendSms-event');
    },
    verifySms: (component, buttonComponent) => {
      // console.log("1. will SEND SMS:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('verifySms-event');
    },
    sendProducts: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendProducts-event', formId);
    },
    doneTrigger: (component, eventResult) => {
      component.$eventHub.$off('done-event-trigger');
      component.loading = false
      // console.log('1. will Done Trigger', eventResult)
      // console.log('event Result',eventResult)
      if (eventResult.status) {
        eventResult.parent.clear()
      }
      if (!eventResult.status && eventResult.message) {
        // console.log("EVENT RESULT>>>", eventResult)
        component.showModal(eventResult.message)
      }

    },
    sendReceipt: (component, buttonComponent)=>{
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendReceipt-event',buttonComponent)
    },
    displayModal (params, component) {
      component.showModal(params.message, params.type)
    },
    popupAndRedirect: (params, component) => {
      component.showModal(params.message, params.type)
      setTimeout(() => { window.location.href = component.$store.state[params.query] }, params.delay);
    },
    setStateVar: (params, component) => {
      params.forEach((param) => { store.state[param.key] = param.value })
    },
    setComponentStatus: (params, component) => {
      store.commit('setComponentStatus', { component: params.component, status: params.status });
    },
    sendLastStepHungary: (params, component) => {
      return new Promise((resolve, reject) => {
        component.loading = true;
        const extraData = { 'isDonations': true }
        store.dispatch('lastStep', { endpoint: '/lastStep', extraData })
        .then((resp) => {
          resolve(resp)
        }).catch((err) => {
          reject(err)
        }).finally(() => {
          component.loading = false;
        })
      })
    },
    sendBarcodeProducts: (component, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => {
          return buttonComponent.doneTrigger(buttonComponent, parent)
        });
      buttonComponent.$eventHub.$emit('sendBarcodeProducts-event');
    },
  }
}
