<template>
  <div class="container">
    <div class="row wrapper">
      <div 
        v-for="(campaign, idx) in activeCampaigns"
        :key="idx"
        class="col-8"
      >
        <button-component
          :text="campaign.campaignName"
          :classes="btnClasses"
          @click.native.prevent="goToCampaign(campaign)"
        />
      </div>
      <div class="text-center" v-if="activeCampaigns.length == 0">
        <h1>No participating campaigns</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import buttonComponent from './button-component.vue';
export default {
  components: { buttonComponent },
  name: "ActiveCampaigns",
  props: {
    endpoint: {
      type: String,
      default: "/getActiveCampaigns"
    },
    marketingCampaignId: {
      type: String,
      default: "marketingCampaignId"
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    styles: {
      type: Object,
      default: () => {}
    },
    btnClasses: {
      type: String,
      default: 'btn btn-primary'
    },
    successAction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeCampaigns: [],
    };
  },
  computed: {
    ...mapGetters(['customerToken']),
  },
  methods: {
    ...mapActions(['getActiveCampaigns']),
    goToCampaign (campaign) {
      this.$store.state.customerToken = campaign.customerToken;
      this.$store.state.receiptToken = campaign.receiptToken;
      this.$store.state.campaignId = campaign.campaignId;
      this.$store.state.referenceNumber = campaign.referenceNumber;
      const fn = this.successAction.fn;
      const params = this.successAction.params;
      return this[fn](params, this);
    },
  },
  async mounted () {
    if (this.customerToken) {
      const fn = this.successAction.fn;
      const params = this.successAction.params;
      return this[fn](params, this);
    }

    const res = await this.getActiveCampaigns({ endpoint: this.endpoint, marketingCampaignId: this.marketingCampaignId });
    console.log(res)
    this.activeCampaigns = res.participations;
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  row-gap: 20px;
}
</style>
