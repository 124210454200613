<template>
  <div class="wrapper">
    <div class="item">
      <div class="digit">
        1
      </div>
      <div class="inner">
        <div v-html="$t('Please write a piece of paper this number')">
        </div>
        <div class="code">
          {{ uniqueCode }}
          <img
            src="@/assets/images/svg/paper.svg"
            alt="Paper"
          >
        </div>
      </div>
    </div>
    <div class="item">
      <div class="digit">
        2
      </div>
      <div class="inner">
        <div>
          <img
            :src="require(`@/${productPhoto}`)"
            alt="Product Image"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "UniqueCode",
  data () {
    return {
      uniqueCode: 'XXX'
    }
  },
  props: {
    endpoint: {
      type: String,
      default: '/createMoneyBackUniqueCode'
    }
  },
  mounted () {
    const formData = {
      campaignId: this.campaignId,
      customerToken: this.customerToken,
      receiptToken: this.receiptToken
    }
    this.sendRequest({ endpoint: this.endpoint, formData }).then(res => {
      this.uniqueCode = res.uniqueCode
    }).catch(err => {
      console.log(err)
    })
  },
  computed: {
    ...mapGetters(['customerToken', 'receiptToken', 'campaignId']),
    productPhoto () {
      return 'assets/images/png/unique-code-oralb.png'
    }
  },
  methods: {
    ...mapActions(['sendRequest'])
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 20px;
    
    .digit {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 5px;
      background: #7879F1;
      border: 1.84999px solid #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 26px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .code {
      display: flex;
      justify-content: space-evenly;
      font-size: 40px;
      line-height: 50px;
      font-weight: 700;
    }
    img {
      max-width: 200px;
    }
  }

}
</style>
