export default {
  'Log in to your account': 'Melde dich mit deinem ForMe-Profil an, um dein Geld zurückzuerhalten!',
  'Email': 'E-Mail',
  'Password': 'Passwort',
  'Forgot your password?': 'Passwort vergessen?',
  'Login': 'ANMELDEN',
  'Confirm your identity and claim your refund': 'Bestätige deine Identität',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Bitte gib deine Telefonnummer ein, um deine Identität zu bestätigen. Dieser Schritt dient der Verfizierung deiner Identität. Im Nachgang werden alle deine Daten gelöscht.',
  'Phone number': 'Telefonnummer',
  'Get SMS Code': 'Erhalte eine SMS',
  'Verification code': 'Gib deinen Bestätigungscode ein',
  'We sent you a verification code via sms': 'Wir haben dir einen Bestätigungscode per SMS zugestellt',
  'Didnt receive it?': 'Du hast keine SMS erhalten?',
  'Click here': 'Klicke hier',
  'Change sms number': 'Telefonnummer ändern',
  'Provide bank details': 'Gib deine Bankdaten ein',
  'Please let us know to which account the refund amount should be transferred.': 'Bitte gib die Kontodaten des Kontos an auf das der Betrag zurückerstattet werden soll.',
  'Account holder first name': 'Vorname des Kontoinhabers',
  'Account holder last name': 'Nachname des Kontoinhabers',
  'Enter the frist name of the account holder': 'Vorname des Kontoinhabers eingeben',
  'Enter the last name of the account holder': 'Nachname des Kontoinhabers eingeben',
  'Enter your IBAN number': 'IBAN eingeben',
  'Continue': 'FORTFAHREN',
  'Send': 'Bestätigen',
  'Back': 'Zurück',
  'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': 'Wir haben deine Zahlungsinformationen erhalten. Das Geld wird innerhalb der nächsten 6 Wochen auf das von dir angegebene Konto überwiesen. Vielen Dank für deine Teilnahme.',
  'Back to campaign page': 'ZURÜCK ZUR STARTSEITE',
  'Back to forme page': 'ZURÜCK ZUR FORME STARTSEITE',
  'receipt-image': 'assets/images/png/receipt-dach.png',
  'Upload a receipt': 'Kaufbeleg hochladen',
  'In this step, please upload the proof of purchase of your products.': 'Bitte lade in diesem Schritt den Kaufbeleg deines Produktes hoch.',
  'Drop your photo here or select file.': 'Klicke hier, um die Datei auszuwählen, oder lade den Kaufbeleg per Drag&Drop hoch.',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Nur JPEG-, PNG- und PDF-Dateien werden unterstützt. Maximale Dateigröße: 10 MB',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Nur JPEG- und PNG-Dateien werden unterstützt. Maximale Dateigröße: 10 MB',
  'UPLOAD': 'HOCHLADEN',
  'The following information must be visible:': 'Die folgenden Informationen müssen deutlich lesbar sein:',
  'Folded or scanned receipts or invoices will not be accepted': 'Gefaltete oder eingescannte Belege oder Rechnungen können nicht akzeptiert werden.',
  'Retailer / Shop': 'Händler / Markt',
  'Date and time of purchase': 'Kaufdatum & Kaufzeit',
  'Campaign product and its price': 'Aktionsprodukt & Kaufpreis',
  'Total sum of the receipt / invoice and applied VAT': 'Gesamtsumme des Belegs & ausgewiesene Mehrwertsteuer',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Bonnummer / Rechnungsnummer (nicht Belegnummer)',
  'Valid': 'Gültig',
  'Hard to read': 'Schwer zu lesen',
  'Folded': 'Zerknittert',
  'Cut off': 'Abgeschnitten',
  'Your receipt is now being loaded and processed.': 'Dein Kaufbeleg wird gerade geladen und bearbeitet.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Dein Kaufbeleg wird automatisch bearbeitet. Dies kann ein paar Minuten dauern, bitte hab ein wenig Geduld.',
  'If you get an error message, please get a better version of the receipt and upload it again.': 'Solltest du eine Fehlermeldung erhalten, mache eine neue Aufnahme von deinem Kaufbeleg und versuche es erneut.',
  'Please drag your receipt inside the gray rectangle.': 'Bitte passe das graue Rechteck an den Kassenbeleg an.',
  'You can erase private information with the Eraser tool.': 'Du kannst private Informationen mit dem Radierer-Tool löschen.',
  'Crop': 'Zuschneiden',
  'Erase': 'Ausradieren',
  'Drag': 'Ziehen',
  'Zoom in': 'Ranzoomen',
  'Zoom out': 'Herauszoomen',
  'Please upload your product photo here': 'Bitte lade in diesem Schritt ein Foto deines Produktes hoch.',
  'Please upload a photo of the back of your product.': 'Bitte lade ein Foto der Rückseite deines Produktes hoch.',
  'Thank you': 'Danke',
  'Thank you for resubmitting your bank details. So there should now be a successful transfer.': 'Vielen Dank für die Aktualisierung deiner Bankdaten. Es wird ein neuer Überweisungsversuch vorgenommen.',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Dieses Dateiformat wird nicht unterstützt. Es werden nur .jpeg, .png und .pdf Dateien akzeptiert.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Dieses Dateiformat wird nicht unterstützt. Es werden nur .jpeg und .png Dateien akzeptiert.',
  'This file is too big. Only files up to 10MB can be uploaded.': 'Diese Datei ist zu groß. Es können nur Dateien bis 10 MB hochgeladen werden.',
  'Copied to clipboard': 'In die Zwischenablage kopiert',
  'Oops!!!': 'Hoppla..',
  'Oops, something went wrong. Please try again.': 'Leider konnte die Seite nicht aufegrufen werden. Bitte kontaktiere die Customersupport-Email, welche du in der Kampagnen Email findest.',
  'Error!!! Something went wrong!': 'Hoppla, da ist etwas schiefgelaufen!',
  'Error!!! Something went wrong, start over!': 'Hoppla, da ist etwas schiefgelaufen! Bitte von vorne anfangen!',
  'The connection you are trying to use has already expired. Please request a new password reset link.': 'Die Sitzung ist leider bereits abgelaufen. Bitte fordere einen neuen Link zum Zurücksetzen deines Passwortes an.',
  'An error occurred while loading the receipt because it has been loaded too many times.': 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
  'Verification code could not be sent to the phone number as it is not allowed. Please check the number you entered and try again.': 'Der Bestätigungscode konnte nicht gesendet werden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfe die angegebene Handynummer und versuche es erneut.',
  'Incorrect SMS code': 'Der Bestätigungscode ist nicht korrekt',
  'Error. There was a server error. Please try again later.': 'Error. Es gab einen Serverfehler. Bitte versuche es später erneut.',
  'This is not a receipt, please upload a valid receipt photo.': 'Bei der von dir hochgeladenen Datei handelt es sich nicht um einen Kaufbeleg. Bitte lade ein gültiges Bonfoto hoch.',
  'Error, please try again later': 'Ein Fehler ist aufgetreten, bitte versuche es später erneut.',
  'Dear Customer, you entered an incorrect email/password. You must log in with the account information you used to participate in the campaign.': 'Lieber Kunde / Liebe Kundin, du hast entweder die E-Mail-Adresse oder das Passwort inkorrekt angegeben. Du musst dich mit denselben Daten einloggen, die du für die Teilnahme an der Aktion verwendet hast.',
  'Business UsePayout Page – Wrong SMS Code Entry Dear Customer, you entered the wrong SMS code. Please try again.': 'Lieber Kunde / Liebe Kundin, der von dir angegebene Bestätigungscode ist nicht korrekt. Bitte versuche es erneut.',
  'Unfortunately the link is no longer valid': 'Leider ist der Link nicht mehr gültig',
  'Your photo has been successfully submitted to us and is now being checked. As soon as your successful participation in the campaign has been confirmed by checking your data, you will receive a corresponding email. If it turns out that your data was incomplete or the purchase receipt could not be recorded, you will receive an email with relevant queries. The testing process can take up to 36 hours; We therefore ask you to be patient.': 'Dein Foto wurde erfolgreich an uns übermittelt und wird jetzt überprüft. Sobald durch die Überprüfung der Daten eine erfolgreiche Teilnahme an der Aktion bestätigt wurde, bekommst du eine entsprechende E-Mail zugesandt. Sollte sich ergeben haben, dass deine Daten unvollständig waren oder der Kaufbeleg nicht erfasst werden konnte, wirst du eine E-Mail mit entsprechenden Rückfragen erhalten. Der Prüfvorgang kann bis zu 36 Stunden in Anspruch nehmen; wir bitten dich daher um etwas Geduld.',
  'Upload proof of purchase + product back': 'Kaufbeleg + Produktrückseite hochladen',
  'In this step, please upload the purchase receipt for your product together with the back of the product. Please make sure that the PZN of the product is clearly visible.': 'Bitte lade in diesem Schritt den Kaufbeleg deines Produktes gemeinsam mit der Rückseite des Produktes hoch. Bitte achte darauf, dass die PZN des Produktes gut erkennbar ist.',
  'Name': 'Vorname',
  'Last name': 'Nachname',
  'Street': 'Straße',
  'House number': 'Hausnummer',
  'City': 'Ort',
  'Zip Code': 'Postleitzahl',
  'Country': 'Land',
  '': '',


  errors: {
    default: 'Hoppla, da ist etwas schiefgelaufen!',
    customerToken: 'Hoppla, da ist etwas schiefgelaufen! Bitte von vorne anfangen!',
    invalidCode: 'Die Sitzung ist leider bereits abgelaufen. Bitte fordere einen neuen Link zum Zurücksetzen deines Passwortes an.',
    manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
    generateCode: 'Der Bestätigungscode konnte nicht gesendet werden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfe die angegebene Handynummer und versuche es erneut.',
    wrongCode: 'Der Bestätigungscode ist nicht korrekt',
    network: 'Error. Es gab einen Serverfehler. Bitte versuche es später erneut.',
    invalidReceipt: 'Bei der von dir hochgeladenen Datei handelt es sich nicht um einen Kaufbeleg. Bitte lade ein gültiges Bonfoto hoch.',
    'Campaign not found!': 'Ein Fehler ist aufgetreten, bitte versuche es später erneut.',
    'User not found': 'Lieber Kunde / Liebe Kundin, du hast entweder die E-Mail-Adresse oder das Passwort inkorrekt angegeben. Du musst dich mit denselben Daten einloggen, die du für die Teilnahme an der Aktion verwendet hast.',
    'Login Failed': 'E-Mail oder Passwort ist falsch. Gib die E-Mail-Adresse und Passwort bitte exakt so an, wie du diese bei der Registrierung angegeben hattest. Groß- und Kleinschreibung müssen beachtet werden.',
    'Login failed': 'E-Mail oder Passwort ist falsch. Gib die E-Mail-Adresse und Passwort bitte exakt so an, wie du diese bei der Registrierung angegeben hattest. Groß- und Kleinschreibung müssen beachtet werden.',
    'Code is incorrect': 'Lieber Kunde / Liebe Kundin, der von dir angegebene Bestätigungscode ist nicht korrekt. Bitte versuche es erneut.',
    'Error sending verification code': 'Der Bestätigungscode konnte nicht gesendet werden, weil es sich um eine nicht zugelassene Mobilrufnummer handelt. Bitte kontrolliere die angegebene Mobilrufnummer und versuche es erneut.',
    'Email is not the same as the user journey': 'E-Mail oder Passwort ist falsch. Gib die E-Mail-Adresse und Passwort bitte exakt so an, wie du diese bei der Registrierung angegeben hattest. Groß- und Kleinschreibung müssen beachtet werden.'
  },
  dictionary: {
    de: {
      messages: {
        'ibanName': '{_field_} kann nur Buchstaben, Bindestriche und Leerzeichen enthalten',
        'iban': 'Ungültige IBAN',
        'phoneNumber': 'Ungültige Telefonnummer',
        plz: 'Ungültige PLZ',
      },
      names: {
        email: 'E-Mail',
        password: 'Passwort',
        iban: 'IBAN',
        firstname: 'Vorname',
        firstName: 'Vorname',
        lastname: 'Nachname',
        lastName: 'Nachname',
        phoneNumber: 'Telefonnummer',
        code: 'Bestätigungscode',
        postal: 'PLZ',
        plz: 'PLZ',
        strasse: 'Straße',
        hausnr: 'Hausnummer',
        city: 'Ort',
        country: 'Land',
        ort: 'Ort',
        street: 'Straße',
        number: 'Hausnummer',
        land: 'Land',
        campaignName: 'Kampagnen Name',
        userEmail: 'E-Mail Adresse',
        supportCaseTitle: 'Anliegen'
      }
    }
  },

}
