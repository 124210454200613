
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // 404
        "pageName": "error-page",
        pageId: 34646,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px",
                        },
                        styles: {
                          "padding": '0 0 0 0;',
                          "max-width": '400px'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/svg/error-image.svg",
                        tabletImageUrl: "assets/images/svg/error-image.svg",
                        mobileImageUrl: "assets/images/svg/error-image.svg"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Oops, something went wrong. Please try again.`,
                        "classes": 'text text-center subheader-text',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "10px",
                          "marginBottom": "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-primary",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "backgroundColor": "#fff",
        "color": "#FFE082",
        "fields": [
        ],
        "meta": [
          { "name": "title", "content": "PG" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG`
          }
        ]
      }
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  formData: new FormData(),
  currentSection: 'loginSection'
}
