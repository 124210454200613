export default {
  'Log in to your account': 'Prihláste sa do svojho účtu',
  'Email': 'Email',
  'Password': 'Heslo',
  'Login': 'Prihlásiť sa',
  'Forgot your password?': 'Zabudli ste heslo?',
  'Get SMS Code': 'ZÍSKAJTE SMS KÓD',
  'We sent you a verification code via sms': 'Poslali sme vám overovací kód prostredníctvom SMS',
  'Didnt receive it?': 'Neprijali ste ho?',
  'Click here': 'Kliknite tu',
  'Change sms number': 'Zmeňte telefónne číslo',
  'Submit': 'ODOSLAŤ',
  'Send': 'ODOSLAŤ',
  'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': 'Máme vaše bankové údaje a budeme vás informovať e-mailom, keď vám pošleme peniaze na váš bankový účet',
  'Upload a receipt': 'Nahrajte potvrdenie (účtenku) o nákupe svojich produktov.',
  'In this step, please upload the proof of purchase of your products.': 'V tomto kroku, prosím nahrajte potvrdenie o nákupe produktov.',
  'Drop your photo here or select file.': 'Presunte fotku sem alebo vyberte súbor.',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Podporované sú iba súbory .jpeg, .png a .pdf. Maximálna veľkosť súboru: 10MB',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Podporované sú iba súbory .jpeg a .png. Maximálna veľkosť súboru: 10MB',
  'UPLOAD': 'Nahrať',
  'The following information must be visible:': 'Nasledujúce informácie musia byť viditeľné:',
  'Folded or scanned receipts or invoices will not be accepted': 'Skladané alebo skenované účtenky alebo faktúry nebudú akceptované',
  'Retailer / Shop': 'Predajca / obchod',
  'Date and time of purchase': 'Dátum a čas nákupu',
  'Campaign product and its price': 'Produkt kampane a jeho cena',
  'Total sum of the receipt / invoice and applied VAT': 'Celková suma účtenky / faktúry a uplatnená DPH',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Číslo účtenky / faktúry (nie ID transakcia z banky)',
  'Valid': 'Platné',
  'Hard to read': 'Ťažko čítateľné',
  'Folded': 'Skladané',
  'Cut off': 'Odstrihnuté',
  'Back': 'Späť',
  'Continue': 'Pokračovať',
  'Please erase your personal data with the erase tool': 'prosím, vymažte svoje osobné údaje pomocou nástroja na vymazanie',
  'Crop': 'Orezať',
  'Erase': 'Vymazať',
  'Drag': 'Ťahať',
  'Zoom in': 'Priblížiť',
  'Zoom out': 'Oddialiť',
  'Provide bank details': 'Poskytnite bankové údaje',
  'Please let us know to which account the refund amount should be transferred.': 'Prosím, dajte nám vedieť na aký účet si želáte preplatiť sumu.',
  'Account holder first name': 'Meno držiteľa účtu',
  'Account holder last name': 'Priezvisko držiteľa účtu',
  'IBAN': 'IBAN',
  'BIC': 'BIC',
  'This field is mandatory': 'Toto pole je povinné',
  'Enter the frist name of the account holder': 'Zadajte krstné meno majiteľa účtu',
  'Enter the last name of the account holder': 'Zadajte priezvisko  majiteľa účtu',
  'Enter your IBAN number': 'Zadajte svoje číslo IBAN',
  'Enter your BIC číslo.': 'Zadajte svoje BIC',
  'Please enter a valid IBAN': 'Zadajte prosím platný IBAN',
  'Confirm your identity and claim your refund': 'Overte svoju totožnosť a uplatnite si náhradu',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Overte svoju totožnosť zadaním svojho telefónneho čísla. Použije sa len raz na overovanie a nie na reklamné účely. Po akcii sa vaše telefónne číslo odstráni.',
  'Mobile phone number': 'Mobilné telefónne číslo',
  'We have sent you a code via SMS to the phone number you provided. Please enter this in the field below. If you did not receive a code, please change your phone number and try again': 'Zaslali sme vám kód prostredníctvom SMS na telefónne číslo, ktoré ste poskytli. Zadajte ho prosím do políčka nižšie. Ak ste kód neobdržali, zmeňte prosím telefónne číslo a skúste to znova',
  'Verification code': 'Overovací Kód',
  'Change the mobile number': 'Zmeňte telefónne číslo',
  'Thank you!!': 'Ďakujem!!',
  'THANK YOU for participating in "xxx" campaign.': 'ĎAKUJEME za účasť v kampani "xxx".',
  'Your receipt / invoice and your data have been successfully sent to us and are being checked at the moment.As soon as we have checked your data, you will receive either a confirmation e-mail or an e-mail asking us if your data was incomplete or your proof of purchase could not be recorded. Please keep your reference number.': 'Vaša účtenka / faktúra a vaše údaje boli úspešne odoslané a momentálne ich preverujeme. Hneď ako skontrolujeme vaše údaje, dostanete potvrdzujúci email alebo email s výzvou k doplneniu chýbajúcich údajov alebo email s informáciou, že vaše dôkazy o nákupe sa nedali zaznamenať. Prosím, nezabudnite si uložiť vaše referenčné číslo.',
  'Reference number': 'Referenčné číslo:',
  'Copy': 'Kopírovať',
  'The verification can take up to 72 hours. We ask for your patience.': 'Overovanie môže trvať až 72 hodín. Prosíme o vašu trpezlivosť.',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Tento formát súboru nie je podporovaný. Podporované sú iba súbory .jpeg, .png a .pdf.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Tento formát súboru nie je podporovaný. Podporované sú iba súbory .jpeg a .png.',
  'This file is too big. Only files up to 10MB can be uploaded.': 'Tento súbor je príliš veľký. Môžu byť nahrané iba súbory do veľkosti 10MB.',
  'Your receipt will now be uploaded and processed.': 'Vaša účtenka sa teraz nahrá a spracuje.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Účtenka sa automaticky spracuje. To môže chvíľu trvať. Prosím, buďte trpezliví.',
  'If you receive an error message, please record a better version of the receipt and upload it again.': 'Ak dostanete chybové hlásenie, nahrajte prosím lepšiu verziu účtenky a skúste to znova.',
  'Please erase your personal data with the eraser tool.': 'Prosím, vymažte svoje osobné údaje pomocou nástroja na vymazanie.',
  'Please frame the receipt / invoice in the gray rectangle.': 'Prosím, ohraničte účtenku / faktúru do sivého obdlžníku.',
  'First name': 'Meno',
  'Last name': 'Priezvisko',
  'Phone number': 'Telefónne číslo',
  'SMS code': 'SMS kód',
  'IBAN is not valid': 'IBAN nie je platný',
  'First name must contain only letters, hyphens and spaces': 'Meno môže obsahovať iba písmená, pomlčky a medzery',
  'Last name must contain only letters, hyphens and spaces': 'Priezvisko môže obsahovať iba písmená, pomlčky a medzery',
  'The phone number is not valid': 'Telefónne číslo nie je platné',
  'Copied to clipboard': 'Skopírované do schránky',
  'Oops, something went wrong!': 'Oops, niečo sa pokazilo!',
  'The link you are trying to use has already expired. Please request a new password reset link.': 'Odkaz, ktorý sa pokúšate použiť, už vypršal. Prosím, požiadajte o nový odkaz na obnovu hesla.',
  'An error occurred because the receipt was uploaded too many times.': 'Pri nahrávaní účtenky došlo k chybe, pretože bola nahraná príliš veľa krát.',
  'The verification code could not be sent because the phone number is not allowed. Please check the number you entered and try again.': 'Overovací kód sa nepodarilo odoslať, pretože telefónne číslo nie je povolené. Prosím, skontrolujte zadané číslo a skúste to znova.',
  'The SMS code is incorrect': 'SMS kód je nesprávny',
  'Oops. An error has occurred on the server. Please try again later.': 'Oops. Na serveri sa vyskytla chyba. Prosím, skúste to znova neskôr.',
  'receipt-image': 'assets/images/svg/receipt-sk.svg',
  'profiling-text': 'Z vášho pokladničného bloku použijeme údaje na to, aby sme prispôsobili zdieľaný obsah vašim záujmom a poskytli vám ponuky, ktoré by vás mohli zaujímat. Zoznámte sa s podmienkami našich kampaní tu: <a href="https://mujsvet-pg.cz/ponuky/pravidla-sk" target="_blank"><u>https://mujsvet-pg.cz/ponuky/pravidla-sk</u></a>.<br>Zmeňte nastavenia <a href="https://mujsvet-pg.cz/ponuky/pravidla-sk" target=_blank><u>tu</u></a>.',
  'Oops...': 'Oops...',
  'Oops, something went wrong. Please try again.': 'Ups, niečo sa pokazilo. Prosím skúste znova.',
  'Back to campaign page': 'Späť na stránku kampane.',
  'This session has expired': 'Platnosť tejto relácie vypršala',
  'Unfortunately the link is no longer valid! Please participate again or contact our support team': 'Odkaz už bohužiaľ nie je platný! Prosím, zúčastnite sa znova alebo kontaktujte náš tím pre podporu',
  'The promotion period is over': 'Platnosť tejto akcie sa skončila.',
  'Sign up to stay up to date. Receive special offers and updates.': 'Zaregistrujte sa, aby ste zostali v obraze. Dostávajte špeciálne ponuky a aktualizácie.',
  'Please upload your receipt again here': 'Prosím, tu nahrajte potvrdenie o nákupe ešte raz.',
  'Please upload your product photo here': 'Sem prosím nahrajte fotografiu vášho produktu.',

  'Specify barcode number': 'Zadajte číslo čiarového kódu',
  'Please scan or enter manually the barcode from your product': 'Naskenujte alebo ručne zadajte čiarový kód z vášho produktu',
  'SCAN': 'SKENOVAŤ',
  'APPLY': 'APLIKOVAŤ',
  'STOP': 'STOP',
  'The barcode number is invalid. Try it again.': 'Číslo čiarového kódu je neplatné. Skús to znova.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Bohužiaľ nie je možné rozpoznať žiadny platný čiarový kód. Skúste zadať čiarový kód do poľa vyššie.',
  'Please turn on your camera': 'Zapnite fotoaparát',
  'Click here and scan the barcode.': 'Kliknite sem a naskenujte čiarový kód.',
  'Enter barcode number': 'Zadajte číslo čiarového kódu',
  'Take a photo of your product unpacked next to the piece of paper with the written unique code on it': 'Odfoťte svoj produkt rozbalený vedľa papierika s napísaným jedinečným kódom',
  'Please write a piece of paper this number': 'Napíšte toto číslo na kúsok papiera',
  'Skip': 'Preskočiť',
  'Expired Total': 'PLATNOSŤ ODKAZU VYPRŠLA',
  'Request your payout': 'Požiadajte o výplatu',
  'Please enter the information of your account to which the refund amount will be transferred.': 'Zadajte informácie o svojom účte, na ktorý bude prevedená suma vrátenia.',

  errors: {
    default: "Oops!!! niečo sa pokazilo!",
    ibanUsed: 'Tento IBAN už bol použitý. Zadajte nový IBAN, uistite sa, že môže prijímať platby zo zahraničia',
    'IBAN is alreaedy in use': 'Tento IBAN už bol použitý. Zadajte nový IBAN, uistite sa, že môže prijímať platby zo zahraničia',
    customerToken: "Oops!!! Niečo sa pokazilo, začnite odznova!",
    invalidCode: "Odkaz, ktorý sa pokúšate použiť, už vypršal. Prosím, požiadajte o nový odkaz na obnovu hesla.",
    manyTries: 'Pri nahrávaní účtenky došlo k chybe, pretože bola nahraná príliš veľa krát.',
    generateCode: 'Overovací kód sa nepodarilo odoslať, pretože telefónne číslo nie je povolené. Prosím, skontrolujte zadané číslo a skúste to znova.',
    wrongCode: 'SMS kód je nesprávny',
    network: 'Oops. Na serveri sa vyskytla chyba. Prosím, skúste to znova neskôr.',
    invalidReceipt: 'Toto nie je účtenka, nahrajte fotografiu platného účtenky.'
  },
  dictionary: {
    sk: {
      messages: {
        "namesWithHyphensAndSpaces": `{_field_} môže obsahovať iba písmená, pomlčky a medzery`,
        "ibanName": `{_field_} môže obsahovať iba písmená, pomlčky a medzery`,
        "iban": "IBAN nie je platný",
        "phoneNumber": "Telefónne číslo nie je platné",
        "mandatory": "Toto pole je povinné",
      },
      names: {
        iban: 'IBAN',
        firstname: 'Meno',
        firstName: 'Meno',
        lastname: 'Priezvisko',
        lastName: 'Priezvisko',
        phoneNumber: 'Telefónne číslo',
        code: 'SMS kód'
      }
    }
  },

  'Procter & Gamble is data controller of the personal data you provide. Data related to your bank account number will be processed for the purpose of providing you with money refund. Your phone number will be processed for the purpose of preventing frauds and abuses. You can exercise your data protection rights or change your preferences and subscriptions by clicking here. You can find out more about how we use your data in our Privacy Policy.': `Spoločnosť <a href='https://privacypolicy.pg.com/sk/#entities' target='_parent'><u>Procter & Gamble</u></a> je správcom osobných údajov, ktoré poskytnete. Údaje súvisiace s číslom vášho bankového účtu budú spracované za účelom poskytnutia vrátenia peňazí. Vaše telefónne číslo bude spracované za účelom predchádzania podvodom a zneužitiu.
  <br>
  Môžete uplatniť svoje <span class='modalOpener' data-text='Ako uvádza v našich zásadách ochrany osobných údajov, máte neobmedzený prístup k svojim osobným údajom, ktoré o vás evidujeme, a môžete ich opravovať, mazať, prenášať k inému poskytovateľovi, môžete namietať proti ich spracovaniu alebo ich spracovanie obmedziť či svoj súhlas odvolať.'>práva na ochranu údajů</span> alebo zmeniť svoje nastavenia a súhlasy kliknutím na <a href='https://privacypolicy.pg.com/sk/#preferences' target='_parent'><u>tento odkaz</u></a>.
  <br>
  Viac o tom, ako používame vaše údaje, nájdete v našich <a href='https://privacypolicy.pg.com/sk/' target='_parent'><u>zásadách ochrany osobných údajov</u></a>.`,
  'We will use the data from your checkout block to tailor shared content to your interests and provide you with offers that may be of interest to you. Get acquainted with the conditions of our campaigns here: https://mujsvet-pg.cz/ponuky/pravidla-sk': `Z vášho pokladničného bloku použijeme údaje na to, aby sme prispôsobili zdieľaný obsah vašim záujmom a poskytli vám ponuky, ktoré by vás mohli zaujímat. Zoznámte sa s podmienkami našich kampaní tu: <a href='https://mujsvet-pg.cz/ponuky/pravidla-sk' target='_blank'>https://mujsvet-pg.cz/ponuky/pravidla-sk</a> Zmeňte nastavenia <a href='https://mujsvet-pg.cz/user/profilena' target='_blank'>tu</a>.`,
  'Email marketing. You are interested in receiving emails, offers and coupons from https://www.mujsvet-pg.cz/ and other trusted brands of P&G .': `<b>E-mailový marketing</b>. Máte záujem dostávať e-maily, ponuky a kupóny od <a href='https://www.mujsvet-pg.cz/' target='_blank'>https://www.mujsvet-pg.cz/</a> a iných dôveryhodných značiek <a href='https://us.pg.com/brands/' target='_blank'>spoločnosti P&G</a>.`,

  'Payout disclaimer': `
  <ul>
    <li>
      Spoločnosť <a href='https://privacypolicy.pg.com/sk/#entities' target='_parent'><u>Procter & Gamble</u></a> je správcom osobných údajov, ktoré poskytnete. Údaje súvisiace s číslom vášho bankového účtu budú spracované za účelom poskytnutia vrátenia peňazí. Vaše telefónne číslo bude spracované za účelom predchádzania podvodom a zneužitiu.
      <br>
      Môžete uplatniť svoje <span class='modalOpener' data-text='Ako uvádza v našich zásadách ochrany osobných údajov, máte neobmedzený prístup k svojim osobným údajom, ktoré o vás evidujeme, a môžete ich opravovať, mazať, prenášať k inému poskytovateľovi, môžete namietať proti ich spracovaniu alebo ich spracovanie obmedziť či svoj súhlas odvolať.'>práva na ochranu údajů</span> alebo zmeniť svoje nastavenia a súhlasy kliknutím na <a href='https://privacypolicy.pg.com/sk/#preferences' target='_parent'><u>tento odkaz</u></a>.
      <br>
      Viac o tom, ako používame vaše údaje, nájdete v našich <a href='https://privacypolicy.pg.com/sk/' target='_parent'><u>zásadách ochrany osobných údajov</u></a>.
    </li>
    <li>
      Z vášho pokladničného bloku použijeme údaje na to, aby sme prispôsobili zdieľaný obsah vašim záujmom a poskytli vám ponuky, ktoré by vás mohli zaujímat. Zoznámte sa s podmienkami našich kampaní tu: <a href='https://mujsvet-pg.cz/ponuky/pravidla-sk' target='_blank'>https://mujsvet-pg.cz/ponuky/pravidla-sk</a> Zmeňte nastavenia <a href='https://mujsvet-pg.cz/user/profilena' target='_blank'>tu</a>.
    </li>
    <li>
      <b>E-mailový marketing</b>. Máte záujem dostávať e-maily, ponuky a kupóny od <a href='https://www.mujsvet-pg.cz/' target='_blank'>https://www.mujsvet-pg.cz/</a> a iných dôveryhodných značiek <a href='https://us.pg.com/brands/' target='_blank'>spoločnosti P&G</a>.
    </li>
  </ul>
  `,

  // Google translated
  'Please enter the first name and last name of the account holder exactly as they would be given at the bank.': 'Zadajte meno a priezvisko majiteľa účtu presne tak, ako by boli uvedené v banke.',
  'Please do not try to enter the same bank details that the first attempt at transfer failed with.': 'Nepokúšajte sa zadať tie isté bankové údaje, s ktorými zlyhal prvý pokus o prevod.',
  'Thank you for resubmitting your bank details.<br>So there should now be a successful transfer. If you have any questions related to your bank details, please contact link.': 'Ďakujeme za opätovné odoslanie bankových údajov.<br>Takže teraz by malo dôjsť k úspešnému prevodu. Ak máte akékoľvek otázky týkajúce sa vašich bankových údajov, kontaktujte <a href="https://mujsvet-pg.cz/kontaktujte-nas" target="_blank"><u>https://mujsvet-pg.cz/kontaktujte-nas</u></a>.'
}
