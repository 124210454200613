<template>
  <span></span>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: "IbanReupload",
  mounted () {
    const customerToken = this.$route.query.customerToken;
    if (customerToken) {
      this.$store.dispatch("isUpdateIban", { customerToken })
        .then(res => {

          // If Customer Token Not Found
          if (!res.response) {
            this.onUpdateIbanError()
            return
          }

          // Everything can proceed as normal
          this.setCustomerToken(customerToken)
        })
        .catch(err => {
          this.onUpdateIbanError()
        })
    } else {
      this.onUpdateIbanError()
    }

  },
  methods: {
    ...mapMutations(['setCustomerToken']),
    onUpdateIbanError () {
      if (!this.isTester) {
        this.goToPage('expired-page', this);
      }
    }
  }
};
</script>
