<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "check-elgibility",
  props: {
    endpoint: String
  },
  data() {
    return {
      timeout: undefined
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.checkEligibility({ endpoint: this.endpoint })
        .catch((e) => {
          if(e.goToExpired){ 
            this.goToPage('expired-page', this)
          } else if (!this.isTester) {
            this.goToPage('error-page', this);
          }
        });
    }, 100);
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions(['checkEligibility'])
  }
};
</script>
