import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash'
import { BootstrapVue } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/mixins';
import '@/vee-validate'
import VueMeta from 'vue-meta'
import i18n from './translate'
import vSelect from 'vue-select'
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

Vue.$cookies.config('4h'); // Set cookie expiration to 7 days


Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import './assets/styles/resp-table.css';


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.prototype._ = lodash

import '@/helpers/common-components.js';
Vue.config.productionTip = false
const EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $eventHub: {
    get: function () {
      return EventBus
    }
  }
})


// Vue.prototype.$eventHub = new Vue();
new Vue({
  store,
  i18n,
  router,
  created () {
    let lang = i18n.locale
    if (lang in i18n.messages) {
      this.$i18n.locale = lang
      this.locale = lang
    }
    let langFile = this.locale == 'gr' ? 'el' : this.locale
    localize(this.locale, require(`vee-validate/dist/locale/${langFile}`))
  },
  render: h => {
    return h(App)
  },
}).$mount('#app > div')

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});
