<template>
  <button
    type="button"
    :class="`btn ${classes}`"
    :style="[styles]"
    :disabled="disabled || loading || dependencies"
    @click="clicked"
  >
    <div v-if="!loading">
      {{ $t(text) }}
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ButtonComponent",
  data() {
    return {
      loading: false
    };
  },
  props: {
    requires: {
      type: Array
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    action: {
      type: [Object, Array]
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapGetters(['hasUnresolvedDependencies']),
    dependencies() {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    async clicked() {
      if (!this.action) return;
      if (this.action.anchor) {
        window.open(this.action.link, '_parent')
        // window.location.href = this.action.link
      }
      if (this.action.length > 0) {
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/mixins/style/_button.mixin";
@import "@/mixins/style/_color";

.btn {
  @include button();

  &-primary {
    background: #E204A3;
    color: #fff;
    border-radius: 8px;

    &:focus,
    &:hover {
      background: #E204A3;
      color: #fff;
    }

    &:active {
      background: #E204A3;
      color: #fff;
    }

    &:disabled {
      opacity: .5;
    }
  }

  &-secondary {
    background-color: #FFF;
    border: 1px solid #E100A1;
    color: #E100A1;

    &:focus,
    &:hover {
      background-color: #FFF;
      border: 1px solid #CA8AB8;
    }

    &:active {
      background-color: #FFF;
      border: 1px solid #FF7EDB;
    }

    &:disabled {
      background-color: #FFF;
      border: 1px solid #DBD7DA;
    }
  }

  &-tertiary {
    background: #8183F0;
    border-radius: 10px;
    border: none;
    color: #FFF;
    &:focus,
    &:hover {
      background: #8183F0;
      border: 1px solid #FFF;
    }
    &:active {
      background: #8183F0;
      border: 1px solid #8183F0;
    }
    &:disabled {
      background: #8183F0;
      border: 1px solid #DBD7DA;
    }
  }

  &-dach {
    background: #FB850F;
    color: #fff;
    border-radius: 8px;

    &:focus,
    &:hover {
      background: #FB850F;
      color: #fff;
    }

    &:active {
      background: #FB850F;
      color: #fff;
    }

    &:disabled {
      opacity: .5;
    }
  }

  &-youtil, &-epithimies {
    background: #D129A1;
    color: #fff;
    border-radius: 8px;

    &:focus,
    &:hover {
      background: #D129A1;
      color: #fff;
    }

    &:active {
      background: #D129A1;
      color: #fff;
    }

    &:disabled {
      opacity: .5;
    }
  }

  &-hungary {
    background: #5D5FEF;
    color: #fff;
    border-radius: 8px;

    &:focus,
    &:hover {
      background: #5D5FEF;
      color: #fff;
    }

    &:active {
      background: #5D5FEF;
      color: #fff;
    }

    &:disabled {
      opacity: .5;
    }
  }

  &-hungary-sec {
    background: #FFF;
    color: #5D5FEF;
    border: 1px solid #5D5FEF;
    border-radius: 8px;

    &:focus,
    &:hover,
    &:active {
      background: #FFF;
      color: #5D5FEF;
    }

    &:disabled {
      opacity: .5;
    }
  }

  &-youtil-sec {
    background: #058AEC;
    color: #fff;
    border-radius: 8px;

    &:focus, &:hover {
      background: #058AEC;
      color: #fff;
    }

    &:active {
      background: #058AEC;
      color: #fff;
    }
    

    &:disabled {
      opacity: .5;
    }
  }
}
</style>
