<template>
  <div class="flex flex-col form-group">
    <ValidationProvider
      v-if="validation"
      :name="name"
      :label="label"
      :rules="rules"
      v-slot="{ handleChange, handleBlur, errors }"
    >
      <label :class="labelClass">
        {{ label }}{{ validation.includes('required') ? '*' : '' }}
      </label>
      <div class="flex flex-col relative p-0">
        <input
          type="file"
          :name="name"
          class="p-2"
          :disabled="disabled"
          :accept="acceptedFile"
          @change="handleChange"
          @blur="handleBlur"
        />
      </div>
      <div class="text-red-500">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
    <template v-else>
      <label :class="labelClass">
        {{ label }}
      </label>
      <div class="flex flex-col relative p-0">
        <input
          type="file"
          :name="name"
          class="p-2"
          :disabled="disabled"
          :accept="acceptedFile"
          @input="updateValue"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  data () {
    return {
      localValue: null
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Name'
    },
    labelClass: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      required: true,
      validator: value => {
        return ['image', 'pdf', 'excel'].includes(value)
      }
    },
    validation: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addToArray: { // don't use true, type array name instead
      type: [Boolean, String],
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    acceptedFile () {
      let accepted = ''
      if (this.fileType === 'image') accepted = `image/png, image/jpeg`
      else if (this.fileType === 'pdf') accepted = `application/pdf`
      else if (this.fileType === 'excel') accepted = `.csv`

      return accepted
    }
  },
  methods: {
    updateValue (event) {
      this.localValue = event.target.files[0]
    }
  },
  watch: {
    localValue (value) {
      this.$emit("component-updated", {
        value: value
      });
    }
  },
}
</script>
