export default {
  isEditMode: state => state.isEditMode,
  pageProperties: state => state.pageProperties,
  getPages: state => Object.keys(state.pageProperties),
  customerToken: state => state.customerToken,
  receiptToken: state => state.receiptToken,
  campaignId: state => state.campaignId,
  receiptImage: state => state.receiptImage,
  productImage: state => state.productImage,
  referenceNumber: state => state.referenceNumber,
  consumerId: state => state.consumerId,
  convertedImagePointsData: state => state.convertedImagePointsData,
  frontAndBack: state => state.frontAndBack,
  countryCode: state => state.countryCode,
  tel: state => state.tel,
  profiling: state => state.profiling,
  iv: state => state.iv,
  token: state => state.token,
  currentSection: state => state.currentSection,
  currentPage: state => {
    return () => {
      if (state.pageStatus) {
        return state.pageStatus.current.pageName
      }
      return undefined
    }
  },
  hasUnresolvedDependencies (state) {
    return fields => {
      if (!fields || !fields.length) {
        return false
      }
      const resolvedFields = fields
        .map(field => {
          return state.componentStatus[field]
        })
        .filter(field => {
          return field
        })

      return resolvedFields.length !== fields.length
    };
  },
  country: (state) => {
    return state.country
  }
}
