<template>
  <h1
    :class="classes"
  >
    {{ $t(text) }}<span>{{ symbol }}</span>
  </h1>
</template>

<script>

export default {
  name: "Heading",
  props: {
    text: {
      type: String,
      default: "Empty heading"
    },
    symbol: {
      type: String,
      default: "."
    },
    classes: {
      type: String,
      default: ""
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  outline: 0;
}
</style>
