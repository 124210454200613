<template>
  <div>
    <div class="title" v-html="$t(title)">
    </div>
    <ul class="list">
      <li
        v-for="(item, idx) in items"
        :key="idx"
        v-html="$t(item)"
      >
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "List",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    classes: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
  }
};
</script>

<style lang="scss" scoped>
ul.list {
    padding-left: 15px;
    margin-top: -1px;
}
ul.list li {
    padding-left: 15px;
    margin-bottom: 2.5em;
    list-style: none;
    background: transparent;
}
.description {
    display: block;
    margin-top: 0.5em;
}


/* BORDERS AND BULLETS */

p {
    /*CSS reset*/
    margin-bottom: 0;
}

ul.list li {
    position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
    /* use padding-bottom instead of margin-bottom.*/ 
    margin-bottom: 0; /* This overrides previously specified margin-bottom */
    padding-bottom: 2.5em;
}

ul.list li:after {
    /* bullets */
    content: url('@/assets/images/svg/bullet.svg');
    position: absolute;
    left: -26px; /*adjust manually*/
    top: 0px;
}

ul.list li:before {
    /* lines */
    content:"";
    position: absolute;
    left: -18px; /* adjust manually */
    border-left: 2px solid #BDBDBD;
    height: 100%;
    width: 1px;
}

ul.list li:first-child:before {
   /* first li's line */
   top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul.list li:last-child:before {
    /* last li's line */
   height: 6px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}

.title {
  margin: 10px 0px 10px 0px;
  font-weight: 800;
  font-size: 18px;
  line-height: 36px;
  text-transform: uppercase;

  /* Primary/PMS 293 C */
  color: #003DA5;


}


</style>
