<template>
  <div>
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div>
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <div>
        <button type="submit">Login</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginTester',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    login() {
      // Simulate authentication by checking hardcoded credentials
      if (this.username === 'admin' && this.password === 'justPass') {
        const token = this.generateToken(); // Generate token locally
        this.$store.commit('loginTester', token);
        // this.goToPage('home', this)
        window.location.href = '/'
      } else {
        // Show error message or handle unsuccessful login
        console.log("Invalid credentials")
        this.showModal('Invalid credentials', 'error')
      }
    },
    generateToken() {
      // Generate token logic
      // Replace this logic with your own token generation mechanism
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let token = '';
      for (let i = 0; i < 32; i++) {
        token += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return token;
    },
  },
};
</script>
