export default {
  'Log in to your account': 'Belépés',
  'Email': 'E-mail',
  'Password': 'Jelszó',
  'Forgot your password?': 'Elfelejtett jelszó',
  'Login': 'BEJELENTKEZÉS',
  'Confirm your identity and claim your refund': 'Erősítse meg személyazonosságát és igényelje a pénzvisszatérítést!',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Igazolja személyazonosságát a telefonszám megadásával. Csak egyszer használjuk ellenőrzésre, hirdetési célokra nem. A művelet után telefonszámát töröljük.',
  'Phone number': 'Telefonszám',
  'Get SMS Code': 'SMS KÓD KÉRÉSE',
  'Verification code': 'Adja meg az ellenőrző kódot',
  'We sent you a verification code via sms': 'SMS-ben elküldtük az ellenőrző kódot. ',
  'Didnt receive it?': 'Nem kapta meg?',
  'Click here': 'Kattintson ide', 
  'Change sms number': 'SMS szám megváltoztatása',
  'Would you like to donate or get cashback?': '<span style="color: #70ad47;">Adományozni</span> szeretne vagy <span style="color: #ff0000;">pénzvisszatérítést?</span>',
  'Donation: To MyForest - Foundation for Community Forests for tree planting. 1 tree cleans 200 million m3 of air during its lifetime, produces 200 kg of oxygen per year and covers the oxygen needs of 1 person per year. The price of planting 1 sapling = HUF 750. Why do we recommend it to MyForest? They not only plant, but also take care of the trees for 10 years afterwards! The seedlings are protected from damage by wild animals with a fence. The planting is carried out with the involvement of experts, the tree species are selected based on the soil composition, so the right tree is placed in the right soil. Every single tree planted, every new hectare of forest is of outstanding importance in terms of reducing air pollution. Refund: by entering your IBAN bank account number, we will transfer the approved amount directly to your account. By clicking on this link, you will find help in entering your IBAN code if you are not sure where to find it.': `<span style="color: #70ad47;">Adományzás:</span> A MyForest - Közösségi Erdőkért Alapítványnak fa ültetésre.
  <br><br>
  1 fa, élete során 200 millió m3 levegőt tisztít, évi 200 kg oxigént termel és évi 1 ember oxigénigényét fedezi. <strong>1 facsemete ültetésének ára = </strong><span style="color: #70ad47;">750 Ft</span> . Miért a MyForest -nek ajánljuk fel? Ők nem csak elültetik, de 10 évig utó is gondozzák a fákat! A csemetéket kerítéssel védik meg a vadak rongálása elől. Az ültetést szakértők bevonásával végzik, a talajösszetétel alapján választják ki a fafajtákat, így a megfelelő fa a megfelelő talajba kerül.
  <br><br>
  <strong>Minden egyes elültetett fa, minden új hektár erdő kiemelkedő jelentőségű a levegőszennyezés csökkentése szempontjából.</strong>
  <br><br>
  <span style="color: #ff0000;">Pénzvissztérítés:</span> IBAN bankszámlaszámod megadásával a jóváhagyott összeget, egyenesen a számládra utaljuk. Erre a <a href='https://www.iban.hu/calculate-iban' target='_blank'>linkre</a> kattintva találsz segítséged az IBAN kódod megadásához, ha nem vagy biztos abban, hol találod.`,
  'Donate': 'ADOMÁNYOZÁS',
  'Or': 'VAGY',
  'Cashback': 'PÉNZVISSZTÉRÍTÉS',
  'Provide bank details': 'Adja meg a banki adatait az utaláshoz',
  'Please let us know to which account the refund amount should be transferred.': 'Kérjük, adja meg melyik számlára utaljuk a visszatérítés összegét .',
  'Account holder first name': 'Számlatulajdonos keresztneve',
  'Account holder last name': 'Számlatulajdonos vezetékneve',
  'Enter the frist name of the account holder': 'Add meg a számlatulajdonos keresztnevét',
  'Enter the last name of the account holder': 'Add meg a számlatulajdonos vezetéknevét',
  'Enter your IBAN number': 'Adja meg IBAN számát',
  'Continue': 'FOLYTATÁS',
  'Send': 'JÓVÁHAGYÁS',
  'Back': 'VISSZA',
  "We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account": "Köszönjük, hogy részt vett a kampányban.",
  "Back to campaign page": "Vissza a program oldalára",
  'receipt-image': 'assets/images/png/receipt-hu.png',
  'Upload a receipt': 'Vásárlási blokk feltöltése',
  "In this step, please upload the proof of purchase of your products.": "Töltsd fel a vásárlását igazoló bizonylatot!",
  "Drop your photo here or select file.": "Húzd át ide a feltöltendő képet, vagy válaszd ki a megfelelő file-tl!",
  "Only JPG, PNG and PDF files are supported Maximum file size: 10MB": "A feltöltendő kép elfogadott formátuma JPG, PNG vagy PDF. A file maximális mérete 10 Mb.",
  "Only JPG and PNG files are supported Maximum file size: 10MB": "A feltöltendő kép elfogadott formátuma JPG vagy PNG. Az állomány file mérete 10 Mb.",
  "UPLOAD": "Feltöltés",
  "The following information must be visible:": "A következő adatoknak láthatónak kell lennie:",
  "Folded or scanned receipts or invoices will not be accepted": "Összehajtogatott, megrongálódott, szkennelt vásárlási bizonylatot nem áll módunkban elfogadni!",
  "Retailer / Shop": "Forgalmazó/kereskedő",
  "Date and time of purchase": "A vásárlás napja és időpontja",
  "Campaign product and its price": "Promóciós termék és a termék ára",
  "Total sum of the receipt / invoice and applied VAT": "A nyugta/számla végösszege (ÁFA-val együtt)",
  "Receipt / Invoice ID (not the bank transaction ID)": "A vásárlási bizonylat kódja (Figyelem, ez nem egyezik meg a banki átutalás kódjával!)",
  "Valid": "Érvényes",
  "Hard to read": "Nehezen vagy egyáltalán nem olvasható",
  "Folded": "Összhajtogatott",
  "Cut off": "Levágott",
  "Your receipt is now being loaded and processed.": "A nyugta feltöltése és feldolgozása folyamatban van.",
  "The receipt will be processed automatically. This may take a minute. Please be patient.": "Feltöltött vásárlási bizonylatodnak feldolgozása automatikusan történik. Ez eltarthat egy darabig. Kérjük, várj türelemmel!",
  "If you get an error message, please get a better version of the receipt and upload it again.": "Hibajelzés esetén próbálj meg feltölteni egy olvashatóbb vásárlási bizonylatot!",
  "Please drag your receipt inside the gray rectangle.": "Húzd a bizonylatot a szürke téglalap közepére!",
  "You can erase private information with the Eraser tool.": "Személyes jellegű információk törlésére kérlek, használd a radír funkciót!",
  "Crop": "Kivágás",
  "Erase": "Törlés",
  "Drag": "Áthúzás",
  "Zoom in": "Nagyítás",
  "Zoom out": "Kicsinyítés",
  "Please upload your product photo here": "Itt töltsd fel ide a termék fotóját!",
  "Please upload a photo of the back of your product.": "Ide tölts fel egy fotót a termék hátuljáról.",
  "Thank you": "Köszönjük!",
  "Thank you for resubmitting your bank details. So there should now be a successful transfer.": "Köszönjük a banki adatok újbóli feltöltését. Most próbáljuk az utalást ismét elindítani!",
  "This file format is not supported. Only .jpeg, .png and .pdf files are accepted.": "Ezt az állománytípust nem áll módunkban elfogadni. Töltsd fel JPG, PNG vagy PDF állományként!",
  "This file format is not supported. Only .jpeg and .png files are accepted.": "Ezt az állománytípust nem áll módunkban elfogadni. Töltsd fel JPG vagy PNG állományként!",
  "This file is too big. Only files up to 10MB can be uploaded.": "Az állomány mérete meghaladja az elfogadott 10 MB maximális méretet!",
  "Copied to clipboard": "A vágólapra másolva",
  "Oops!!!": "Upsssz!",
  "Oops, something went wrong. Please try again.": "Uppsssz! Hiba történt! Próbálkozz újra!",
  "Error!!! Something went wrong!": "Hiba történt!",
  "Error!!! Something went wrong, start over!": "Hiba történt! Próbáld meg újrakezdeni a folyamatot!",
  "The connection you are trying to use has already expired. Please request a new password reset link.": "A használni kívánt kapcsolat mér lejárt. Kérj egy új jelszó-visszaállítási linket!",
  "An error occurred while loading the receipt because it has been loaded too many times.": "A vásárlási bizonylat betöltése a túl sok betöltési kísérlet miatt érvénytelenné vált!",
  "Verification code could not be sent to the phone number as it is not allowed. Please check the number you entered and try again.": "A megerősítő kódot nem lehet elküldeni a megadott telefonszámra, mert nem engedélyezett. Kérjük, ellenőrizd a megadott számot és próbáld újra.",
  "Incorrect SMS code": "Helytelen SMS-kód!",
  "Error. There was a server error. Please try again later.": "Hiba! Szerverhiba miatt a folyamat hibát jelzett, próbáld újra!",
  "This is not a receipt, please upload a valid receipt photo.": "A feltöltött állomány nem vásárlási bizonylat. Kizárólag bizonylatot tölts fel!",
  "Error, please try again later": "Hiba történt! Kérlek, próbálkozz később!",
  "Dear Customer, you entered an incorrect email/password. You must log in with the account information you used to participate in the campaign.": "Nem megfelelő azonosító vagy jelszó! Lépj be a program során korábban megadott adatokkal!",
  "Business UsePayout Page – Wrong SMS Code Entry Dear Customer, you entered the wrong SMS code. Please try again.": "Helytelen SMS-kód! Kérlek, próbáld újra!",
  "Unfortunately the link is no longer valid": "A megadott link érvényét vesztette!",
  'Procter & Gamble is data controller of the personal data you provide. Data related to your bank account number will be processed for the purpose of providing you with money refund. Your phone number will be processed for the purpose of preventing frauds and abuses. You can exercise your data protection rights or change your preferences and subscriptions by clicking here. You can find out more about how we use your data in our Privacy Policy.': `A <a href='https://privacypolicy.pg.com/hu/#entities' target='_blank'>Procter & Gamble</a> az Ön által megadott személyes adatok adatkezelője. A bankszámlaszámhoz kapcsolódó adatokat a pénzvisszatérítés biztosítása érdekében kezeljük. Telefonszámát a csalások és visszaélések megelőzése érdekében dolgozzuk fel.
  <br><br>
  Gyakorolhatja <a href='https://www.everydayme.hu/data-information' target='_blank'>adatvédelmi jogait</a> vagy módosíthatja preferenciáit és előfizetéseit <a href='https://privacypolicy.pg.com/hu/#preferences' target='_blank'>ide</a> kattintva.
  <br><br>
  Az <a href='https://privacypolicy.pg.com/hu/' target='_blank'>adatkezelési szabályzatunkban</a> többet megtudhat arról, hogyan használjuk fel adatait.`,
  'Payout disclaimer': `A <a href='https://privacypolicy.pg.com/hu/#entities' target='_blank'>Procter & Gamble</a> az Ön által megadott személyes adatok adatkezelője. Ha folytatja a fiók létrehozását és a pénzvisszatérítési programban való részvételt, a feltölött nyugtáját összekapcsoljuk a létrehozott fiókkal, és a pénzvisszatérítési pályázatának érvényesítésére használjuk. Ha önként hozzájárult, a nyugta adatait az Önnel megosztott tartalmak és ajánlatok személyre szabásához használjuk.
  <br><br>
  <b>Az Ön választási lehetőségei.</b> Ön élhet az <a href='https://www.everydayme.hu/data-information' target='_blank'>adatvédelemre vonatkozó jogaival</a>, illetve módosíthatja beállításait és regisztrációját, ha <a href='https://www.pg.com/privacy/hungarian/privacy_statement.shtml#preferences' target='_blank'>ide</a> kattint
  <br><br>
  Az adatok felhasználásával kapcsolatos további információkért olvassa el a <a href='https://privacypolicy.pg.com/HU/' target='_blank'>P&G adatvédelmi szabályzatát</a>.`,
  "Sorry, this link is no longer valid. It looks like you didn't submit your payout request in time according to the period specified in the official campaign terms & conditions. Please, next time don't miss the deadline to be able to get cashback.": 'Sajnáljuk, ez a link már nem érvényes. Úgy tűnik, nem küldte be időben kifizetési kérelmét a hivatalos program felhasználási feltételekben meghatározott időszaknak megfelelően. Kérjük, legközelebb ne mulassza el a határidőt, hogy pénzvisszatérítétését el tudjuk utalni.',
  'Request your payout': 'Kifizetés igénylése',
  'Please enter the information of your account to which the refund amount will be transferred.': 'Kérjük, adja meg annak a bankszámlának az adatait, amelyre a visszatérítés összege átutalásra kerül.',
  'Specify barcode number': 'Adja meg a vonalkód számát',
  'Please scan or enter manually the barcode from your product': 'Kérjük, szkennelje be vagy adja meg manuálisan a termék vonalkódját',
  'SCAN': 'SZKENN',
  'APPLY': 'ALKALMAZ',
  'STOP': 'LEÁLLÍTÁS',
  'The barcode number is invalid. Try it again.': 'A vonalkód száma érvénytelen. Próbáld újra.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Sajnos nem lehet felismerni érvényes vonalkódot. Kérjük, próbálja meg beírni a vonalkódot a fenti mezőbe.',
  'Please turn on your camera': 'Kérjük, kapcsolja be a kamerát',
  'Click here and scan the barcode.': 'Kattintson ide és szkennelje be a vonalkódot.',
  'Enter barcode number': 'Adja meg a vonalkód számát',
  'Take a photo of your product unpacked next to the piece of paper with the written unique code on it': 'Készítsen egy fotót a kibontott termékről a papírlap mellett, amelyen a ráírt egyedi kód látható.',
  'Please write a piece of paper this number': 'Kérjük, írja le egy papírlapra ezt a számot',
  'Skip': 'Kihagyás',
  'You have already added this product.': 'Már hozzáadtad ezt a terméket.',
  'You have already added the maximum number of products.': 'Már hozzáadtad a maximális termékszámot.',
  '': '',


  errors: {
    default: "Hoppá, valami hiba történt!",
    customerToken: "Hoppá, valami hiba történt! Kérjük, kezdje elölről!",
    invalidCode: "A munkamenet sajnos már lejárt. Kérjük, kérjen új linket a jelszó visszaállításához.",
    manyTries: "Hiba történt, mert a nyugtát túl sokszor töltötték fel.",
    generateCode: "A megerősítő kódot nem lehet elküldeni, mert a telefonszám nem engedélyezett. Kérjük, ellenőrizze a megadott telefonszámot, és próbálja újra.",
    wrongCode: "A megerősítő kód nem helyes",
    network: "Hiba. Szerverhiba történt. Kérjük, próbálja meg később.",
    invalidReceipt: "A feltöltött fájl nem vásárlási bizonylat. Kérjük, töltse fel az érvényes nyugta fotóját.",
    "Campaign not found!": "Hiba történt, kérjük próbálja meg később.",
    "User notfound": "Kedves Ügyfél, vagy az e-mail címet, vagy a jelszót helytelenül adta meg. Ugyanazzal az adattal kell bejelentkeznie, amelyet a promócióban való részvételhez használt.",
    "Login Failed": "Kedves Ügyfél, vagy az e-mail címet, vagy a jelszót helytelenül adta meg. Ugyanazzal az adattal kell bejelentkeznie, amelyet a promócióban való részvételhez használt.",
    "Login failed": "Kedves Ügyfél, vagy az e-mail címet, vagy a jelszót helytelenül adta meg. Ugyanazzal az adattal kell bejelentkeznie, amelyet a promócióban való részvételhez használt.",
    "Code is incorrect": "Kedves Ügyfél, az általad megadott megerősítő kód nem helyes. Kérjük, próbálja meg újra."
  },
  dictionary: {
    hu: {
      messages: {
        'ibanName': '{_field_} csak betűket, kötőjeleket és szóközöket tartalmazhat',
        'iban': 'Érvénytelen IBAN',
        'phoneNumber': 'Érvénytelen telefonszám',
        "mandatory": "A mező kitöltése kötelező",
      },
      names: {
          email: 'E-mail',
          password: 'Jelszó',
          iban: 'IBAN',
          firstname: 'Keresztnév',
          firstName: 'Keresztnév',
          lastname: 'Vezetéknév',
          lastName: 'Vezetéknév',
          phoneNumber: 'Telefonszám',
          code: 'Megerősítő kód',
      }
    }
  },

}
