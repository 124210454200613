<template>
  <span></span>
</template>

<script>

export default {
  name: "IframeEvents",
  mounted () {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          if (entry.contentBoxSize[0]) {
            setTimeout(() => {
              window.top.postMessage(
                { request: 'iframeHeight', value: `${entry.target.scrollHeight}` },
                '*'
              )
            }, 500);
          }
        }
      }
    });
    resizeObserver.observe(document.body);
  }
};
</script>
