<template>
  <div ref="footerWrapper" class="footerWrapper" :style="[cssVars, styles]">
    <div v-if="!!logo" class="logoWrapper" :style="logo.styles">
        <a :href="logo.url" target="_blank" aria-label="Swiffer Website">
          <img class="brandLogo"
          :src="require(`@/${logo.src}`)"
          :alt="logo.alt" />
        </a>
        <div v-if="!!socialMedia && socialMedia != {}" class="socialMediaWrapper">
          <span class="socialMediaText" v-html="socialMedia.text"></span>
          <div class="socialMediaIconsWrapper">
            <div
              class="socialMediaIcon"
              v-for="icon in socialMedia.icons"
              :key="`smIcon_${icon.id}`"
            >
              <a style="display: contents" :href="icon.href" target="_blank">
                <img :src="icon.imageSrc" alt="socialMediaIcon" />
              </a>
            </div>
          </div>
        </div>
    </div>
    <div class="top" v-if="fields.length">
      <div class="linksWrapper" v-for="(field, idx) in fields" :key="idx">
        <componentRenderer v-bind:key="field.id" :field="field" />
      </div>
    </div>
  </div>
</template>

<script>
import componentRenderer from "@/components/component-renderer.vue";
export default {
  name: "footer-component",
  components: {
    componentRenderer
  },
  props: {
    background: {
      type: String,
      default: "#000"
    },
    styles: {
      type: Object,
      default: () => {
        return {
          "background": "#FFF",
          "color": "#000"
        }
      }
    },
    socialMedia: {
      type: Object,
      required: false,
      default: () => {}
    },
    logo: {
      type: Object,
      required: false,
      default: () => {}
    },
    fields: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.styles.color,
        '--bg-color': this.styles.background
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.logoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  img {
    position: absolute;
    top: -30px;
    max-width: 200px;
    margin: 0 20px;
  }
}
.footerWrapper {
  padding-bottom: 50px;
  color: var(--color);
  @media(max-width: 450px){
    max-width: 100vw !important;
  }

  .socialMediaWrapper {
    display: flex;
    align-items: center;
    min-height: 50px;

    .socialMediaIconsWrapper {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      .socialMediaIcon {
        margin: 5px;
        background-color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .top {
    .linksWrapper {
      padding-top: 25px;
    }
  }
}
</style>
