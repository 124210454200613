var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sms-wrapper row",style:(_vm.cssVars),attrs:{"id":_vm.fieldId}},[_c('ValidationObserver',{ref:"numberForm",staticClass:"col-12 col-md-6",class:{ 'active': _vm.state == 'number' },attrs:{"id":"numberForm"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(_vm.texts.phoneLabel))+" ")]),_c('div',{staticClass:"inputWrapper"},[_c('div',{ref:"selectWrapper",staticClass:"col-lg-4"},[_c('v-select',{attrs:{"options":_vm.options,"label":"name","value":"code","clearable":false,"searchable":false},scopedSlots:_vm._u([{key:"option",fn:function({ code, phoneCode }){return [_c('div',{staticClass:"country-item"},[_c('div',[_c('country-flag',{attrs:{"country":code,"size":"normal"}})],1),_c('div',[_c('b',[_vm._v(_vm._s(phoneCode))])])])]}},{key:"selected-option",fn:function({ code, phoneCode }){return [_c('div',{staticStyle:{"display":"flex","align-items":"center","column-gap":"10px"}},[_c('div',[_c('country-flag',{attrs:{"country":code,"size":"normal"}})],1),_c('div',[_c('b',[_vm._v(_vm._s(phoneCode))])])])]}}]),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('div',{staticClass:"col-lg-8"},[_c('textInput',{staticClass:"phoneNumber col-lg-12",attrs:{"id":"phoneNumber","name":"phoneNumber","label":"","placeholder":"123456789","validation":`required|phoneNumber`,"label-on-top":false,"no-label":true,"styles":_vm.inputStyles,"slide-up":_vm.tel != ''},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)]),_c('div',{staticClass:"btn-wrapper"},[_c('button-component',{attrs:{"text":_vm.texts.sendBtn,"classes":_vm.btnClasses,"requires":['phoneNumber'],"action":{
            fn: 'sendSms',
            params: ''
          }}})],1)])]),(_vm.step1Status)?_c('div',{staticClass:"sms-step sms-step-1"},[_c('img',{staticClass:"sms-step-image",attrs:{"src":require('@/assets/images/png/sms-step.png')}})]):_vm._e(),_c('ValidationObserver',{ref:"codeForm",staticClass:"col-12 col-md-6",class:{ 'active': _vm.state == 'code' },attrs:{"id":"codeForm"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(_vm.texts.codeLabel))+" ")]),_c('v-otp-input',{attrs:{"input-classes":"otp-input","num-inputs":4,"separator":"","should-auto-focus":false},on:{"on-change":_vm.handleOnChange,"on-complete":_vm.handleOnChange}}),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(_vm.texts.codeSentLabel))+" ")]),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(_vm.texts.notReceived))+" "),_c('a',{on:{"click":_vm.goToNumberSection}},[_vm._v(_vm._s(_vm.$t(_vm.texts.clickHere)))])]),_c('div',{staticClass:"label"},[_c('a',{on:{"click":_vm.goToNumberSection}},[_vm._v(_vm._s(_vm.$t(_vm.texts.changeNumber)))])]),_c('div',{staticClass:"btn-wrapper mt-0"},[_c('button-component',{attrs:{"text":_vm.texts.codeSendBtn,"classes":_vm.btnClasses,"requires":['code'],"action":{
            fn: 'verifySms',
            params: ''
          }}})],1)],1)]),(_vm.step2Status)?_c('div',{staticClass:"sms-step sms-step-2"},[_c('img',{staticClass:"sms-step-image",attrs:{"src":require('@/assets/images/png/sms-step.png')}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }