
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // Login Page
        "pageName": "moneyBack",
        isPublic: true,
        pageId: 101,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "loginSection",
            "fields": [
              {
                "fieldType": "query-string-to-state",
                "fieldId": "query-string-to-state",
                "properties": {
                  grid: { columns: 12 },
                  variables: [
                    { state: 'customerToken', query: "cToken" },
                    { state: 'receiptToken', query: "rToken" },
                    { state: 'campaignId', query: "campaignId" },
                    { state: 'referenceNumber', query: "referenceNumber" }
                  ]
                }
              },
              {
                "fieldType": "check-elgibility",
                "fieldId": "check-elgibility",
                "properties": {
                  grid: { columns: 12 },
                  endpoint: "/checkEligibility",
                }
              },
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Log in to your account",
                        classes: 'heading text-center'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "login",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "barcode"
                        },
                        "endpoint": "/janrainLogin",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "50px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "email",
                                          "id": "email",
                                          "label": "Email",
                                          "validation": "required|email",
                                          "labelOnTop": true,
                                          "placeholder": "Email",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "password",
                                          "id": "password",
                                          "validation": "required",
                                          "labelOnTop": true,
                                          "label": "Password",
                                          "placeholder": "Password",
                                          "inputType": "password",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column"
                                    },
                                    fields: [
                                      { // SEND Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 3,
                                            marginBottom: "30px"
                                          },
                                          text: "Login",
                                          classes: "btn-primary",
                                          requires: ['email', 'password'],
                                          "action": [
                                            {
                                              "fn": "sendForm",
                                              "params": "login"
                                            }
                                          ]
                                        }
                                      },
                                      { // SEND Button
                                        "fieldType": "anchor",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          text: "Forgot your password?",
                                          url: 'https://www.everydayme.pl/zresetuj-haslo',
                                          target: '_blank',
                                          styles: {
                                            "font-size": "18px",
                                            "line-height": "38px",
                                            "font-weight": "700",
                                            "color": "#2F80ED",
                                            "text-align": "center",
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Barcode Scanner
        "pageName": "barcode",
        isPublic: true,
        pageId: 1009,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Specify barcode number",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Please scan or enter manually the barcode from your product",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Barcode Scanner
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Barcode Scanner
                            "fieldType": "barcode-scanner",
                            "fieldId": "barcodeScanner",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "product-upload"
                              },
                              "endpoint": "/submitProducts",
                              "texts": {
                                receiptUpload: 'Upload',
                                receiptUploadDescription: 'Upload barcode image',
                                receiptUploadSubDescription: 'Drag & Drop',
                                wrongFormat: 'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 16 MB are supported.',
                                barcodeScan: 'SCAN',
                                stop: 'STOP',
                                barcodeScanDescription: 'Click here and scan the barcode.',
                                barcodeScanSubDescription: '',
                                barcodeAdd: 'APPLY',
                                barcodeAddDescription: 'Enter barcode number',
                                notParticipating: 'The barcode does not appear to belong to a P&G product. Do you think this is an error?',
                                notParticipatingVideo: 'Unfortunately, the product does not appear to be participating in the promotion. Please try to enter the barcode in the field above.',
                                timedOut: 'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.',
                                cameraNotFound: 'Please turn on your camera',
                                invalidBarCode: 'The barcode number is invalid. Try it again.'
                              },
                              "cameraTimeOut": 20000,
                              "maxProductCount": 1
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              fields: [
                                { // Continue Button
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 3
                                    },
                                    text: "Continue",
                                    classes: "btn-primary",
                                    "requires": ["barcodeScanner"],
                                    "action": {
                                      "fn": "sendBarcodeProducts",
                                      "params": ""
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Upload
        "pageName": "product-upload",
        isPublic: true,
        pageId: 1201,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 10,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Take a photo of your product unpacked next to the piece of paper with the written unique code on it",
                        classes: 'heading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "codePhoto",
                            "properties": {
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you",
                                hasLastStep: true
                              }
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "unique-code",
                                  "fieldId": "uwxo2ox",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                    },
                                    "endpoint": "/createMoneyBackUniqueCode",
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        fields: [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              fields: [
                                { // Continue Button
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 3
                                    },
                                    text: "Continue",
                                    classes: "btn-primary",
                                    "requires": ["codePhoto"],
                                    "action": [
                                      {
                                        "fn": "sendProductPhotos",
                                        "params": "codePhoto"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you
        "pageName": "thank-you",
        pageId: 34673457,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Thank you Bucket
                "fieldId": "thankBox",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          // "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/banner-pl.png",
                        tabletImageUrl: "assets/images/banner/banner-pl.png",
                        mobileImageUrl: "assets/images/banner/banner-pl.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account",
                        classes: 'text text-center'
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "82358325",
                "properties": {
                  "grid": {
                    "columns": "5",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px"
                        },
                        classes: "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.everydayme.pl"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // 404
        "pageName": "error-page",
        pageId: 34646,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "oopsErrorPage",
                      "properties": {
                        "text": `Oops!!!`,
                        classes: "text-center header-text",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px",
                          "marginBottom": "40px",
                        }
                      }
                    },
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px",
                        },
                        styles: {
                          "padding": '0 0 0 0;',
                          "max-width": '400px'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/svg/error-dach.svg",
                        tabletImageUrl: "assets/images/svg/error-dach.svg",
                        mobileImageUrl: "assets/images/svg/error-dach.svg"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Sorry, this link is no longer valid. It looks like you didn't submit your payout request in time according to the period specified in the official campaign terms & conditions. Please, next time don't miss the deadline to be able to get cashback.`,
                        "classes": 'text text-center subheader-text',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "10px",
                          "marginBottom": "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-primary",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired Page
        "pageName": "expired-page",
        pageId: 34647,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "oopsErrorPage",
                            "properties": {
                              "text": `Unfortunately the link is no longer valid`,
                              classes: "heading text-left",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "8",
                                "marginTop": "50px",
                                "marginBottom": "40px",
                              }
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px",
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "5vk986f7",
                            "properties": {
                              "imageSource": "assets/images/png/expired-de.png",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 6,
                                "marginBottom": "50px",
                              },
                              "className": "col-md-12 col-lg-6",
                              "styles": {
                                "maxWidth": "400px",
                                "object-fit": "contain"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-primary",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "receipt-reupload",
        isPublic: true,
        pageId: 119,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Upload a receipt",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "In this step, please upload the proof of purchase of your products.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              "colorSchema": "#5D5FEF",
                              "phrases": [
                                "Your receipt is now being loaded and processed.",
                                "The receipt will be processed automatically. This may take a minute. Please be patient.",
                                "If you get an error message, please get a better version of the receipt and upload it again."
                              ],
                              "cornerDetectionPhrases": [
                                "Please drag your receipt inside the gray rectangle.",
                                "You can erase private information with the Eraser tool.",
                              ],
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "receiptImage": "assets/images/svg/receipt-dach.svg",
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: "Send",
                              classes: "btn-primary",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Re Upload
        "pageName": "product-reupload",
        isPublic: true,
        pageId: 120,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Please upload your product photo here",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "Please upload a photo of the back of your product.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "product-upload",
                            "fieldId": "prodPhoto",
                            "properties": {
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              "colorSchema": "#5D5FEF",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "reUpload": true,
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-primary",
                              "requires": ["prodPhoto"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhoto"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "iban-reupload",
        isPublic: true,
        pageId: 123,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                  ]
                }
              },
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Provide bank details",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please let us know to which account the refund amount should be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "bankRe",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iban"
                        },
                        "endpoint": "/updateIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "30px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "border": "1px solid #5D5FEF",
                                "box-shadow": "0px 0px 16px rgba(185, 185, 255, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban:PL",
                                    "ibanPrepend": "PL",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-primary",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "bankRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "thank-you-iban",
        pageId: 124,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Thank you",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Thank you for resubmitting your bank details. So there should now be a successful transfer.",
                        classes: 'text text-center'
                      }
                    },
                    { // Back to home Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: "Back to campaign page",
                        classes: "btn-primary",
                        styles: {
                          "max-width": "600px"
                        },
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "backgroundColor": "#fff",
        "color": "#FFE082",
        "logo": {
          "imageSource": "assets/images/png/everydayme-logo-pl.jpg",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "fields": [
        ],
        "meta": [
          { "name": "title", "content": "PG" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG`
          }
        ]
      }
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '123',
  formData: new FormData(),
  currentSection: 'loginSection',
  isDonations: false,
  country: 'PL',
  imgList: [],
  scannedProducts: null,
  currentBarcode: '',
  productCode: '',
  searchStatus: null,
  scannerActive: false,
  barcode: {
    code: '',
    format: ''
  },
}
