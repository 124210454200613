export default {
  'Log in every day for new offers and content!': 'Para iadenizi alabilmek için giriş yapın',
  'Email': 'E-Posta',
  'Password': 'Şifre',
  'Confirm your identity and claim your refund': 'Kimliğinizi onaylayın ve geri ödemenizi talep edin',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Telefon numaranızı buraya girerek kimliğinizi doğrulayın. Sadece doğrulama amaçlı bir kez kullanılır, reklam amaçlı değildir. İşlem sonrası telefon numaranız tarafımızca silinecektir.',
  'Phone number': 'Telefon Numarası',
  'Verification code': 'Doğrulama kodunu giriniz',
  'We sent you a verification code via sms': 'Size SMS yoluyla bir doğrulama kodu gönderdik',
  'Didnt receive it?': 'Henüz Almadınız mı?',
  'Click here': 'Buraya tıklayın',
  'Change sms number': 'Sms numarasını değiştir',
  'Provide bank details': 'Banka bilgilerini sağlayın',
  'Please let us know to which account the refund amount should be transferred.': 'Lütfen iade tutarının hangi hesaba havale edilmesi gerektiğini bize bildirin.',
  'Account holder first name': 'Hesap Sahibi Adı',
  'Account holder last name': 'Hesap Sahibi Soyadı',
  'Enter the frist name of the account holder': 'Hesap sahibinin adını girin',
  'Enter the last name of the account holder': 'Hesap sahibinin soyadını girin',
  'Enter your IBAN number': "IBAN'ınızı girin",
  'Send': 'Gönder',
  'SEND': 'GÖNDER',
  'Back': 'Späť',
  'Get SMS Code': 'SMS Kodu Al',
  'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': 'Banka bilgileriniz bize ulaştı, para iadenizi gerçekleştirdikten sonra sizi mail yolu ile bilgilendireceğiz.',
  'Upload a receipt': 'Faturanızı yükleyin',
  'In this step, please upload the proof of purchase of your products.': 'Bu adımda, lütfen ürünlerinizin faturasını yükleyin.',
  'Drop your photo here or select file.': 'Fotoğrafınızı buraya bırakın veya dosyayı seçin.',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Yalnızca JPG, PNG ve PDF dosyaları desteklenir<br>Maksimum dosya boyutu: 10MB',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Yalnızca JPG ve PNG dosyaları desteklenir<br>Maksimum dosya boyutu: 10MB',
  'UPLOAD': 'YÜKLE',
  'The following information must be visible:': 'Aşağıdaki bilgiler görünür olmalıdır:',
  'Folded or scanned receipts or invoices will not be accepted': 'Katlanmış veya yıpranmış makbuzlar veya faturalar kabul edilmeyecektir.',
  'Retailer / Shop': 'Dükkan / süpermarket',
  'Date and time of purchase': 'Promosyon ürünleri ve satın alma fiyatı',
  'Campaign product and its price': 'Toplam belge ve bildirilen KDV',
  'Total sum of the receipt / invoice and applied VAT': 'Satın Alma Tarihi ve Satın Alma Saati',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Fiş numarası (belge numarası değil)',
  'Valid': 'Geçerli',
  'Hard to read': 'Okunması zor',
  'Folded': 'Katlanmış',
  'Cut off': 'Kesilmiş',
  'Your receipt is now being loaded and processed.': 'Fişiniz şimdi yükleniyor ve işleniyor.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Fiş otomatik olarak işlenecektir. Bu bir dakika sürebilir. Lütfen sabırlı olunuz.',
  'If you get an error message, please get a better version of the receipt and upload it again.': 'Bir hata mesajı alırsanız, lütfen fişin daha iyi bir versiyonunu alın ve tekrar yükleyiniz.',
  'Please drag your receipt inside the gray rectangle.': 'Lütfen fişinizi gri dikdörtgenin içine sürükleyiniz.',
  'You can erase private information with the Eraser tool.': 'Silgi aracıyla özel bilgileri silebilirsiniz.',
  'Crop': 'Kırp',
  'Erase': 'Sil',
  'Drag': 'Sürükle',
  'Zoom in': 'Yakınlaş',
  'Zoom out': 'Uzaklaş',
  'Please upload your product photo here': 'Ürün fotoğrafı yükle',
  'Please upload a photo of the back of your product.': 'Lütfen ürününüzün arka yüzünün fotoğrafını yükleyiniz.',
  'Thank you': 'Teşekkürler',
  'Thank you for resubmitting your bank details. So there should now be a successful transfer.': 'Banka bilgilerinizi yeniden gönderdiğiniz için teşekkür ederiz. Yani artık başarılı bir transfer olmalı.',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Bu dosya formatı desteklenmiyor. Yalnızca .jpeg, .png ve .pdf dosyaları kabul edilir.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Bu dosya formatı desteklenmiyor. Yalnızca .jpeg ve .png dosyaları kabul edilir.',
  'This file is too big. Only files up to 10MB can be uploaded.': "Bu dosya çok büyük. Yalnızca 10 MB'a kadar olan dosyalar yüklenebilir.",
  'Copied to clipboard': 'Panoya kopyalandı',
  'Oops!!!': 'Bir şeyler yanlış gitti.',
  'Oops, something went wrong. Please try again.': 'Üzgünüz, erişmek istediğiniz sayfa bulunamadı.',
  'Back to campaign page': 'KAMPANYA SAYFASINA GERİ DÖN',
  'Thank you message': 'Bilgileriniz bize ulaştı. Katılımınız için çok teşekkür ederiz.',
  'Thank you button': 'Diğer Kampanyalara Gözat',
  'Our campaign has ended. See you in other campaigns :)': 'Kampanyamız sona erdi. Diğer kampanyalarda görüşmek üzere :)',
  'Request your payout': 'Geri iadenizi isteyiniz',
  'Please enter the information of your account to which the refund amount will be transferred.': 'Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.',
  'Your IBAN information has been shared successfully!': 'IBAN bilgileriniz, başarılı bir şekilde paylaşılmıştır!',

  errors: {
    default: "Hata!!! bir şeyler yanlış gitti!",
    customerToken: "Hata!!! Bir şeyler ters gitti, baştan başlayın!",
    invalidCode: "Kullanmaya çalıştığınız bağlantının süresi zaten dolmuş. Lütfen yeni bir parola sıfırlama bağlantısı isteyin.",
    manyTries: "Fiş çok fazla yüklendiği için yüklenirken bir hata oluştu.",
    generateCode: "Telefon numarasına izin verilmediğinden doğrulama kodu gönderilemedi. Lütfen girdiğiniz numarayı kontrol edin ve tekrar deneyin.",
    wrongCode: "SMS kodu yanlış",
    network: "Hata. Sunucuda bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
    invalidReceipt: "Bu bir fiş değil, lütfen geçerli bir fişin fotoğrafını yükleyin.",
    'Campaign not found!': 'Hata, daha sonra tekrar deneyin',
    'User not found': 'Değerli Tüketicimiz, hatalı e-posta/ şifre girişi yaptınız. Kampanyaya katıldığınız hesap bilgileri ile giriş yapmalısınız.',
    'Login Failed': 'Değerli Tüketicimiz, hatalı e-posta/ şifre girişi yaptınız. Kampanyaya katıldığınız hesap bilgileri ile giriş yapmalısınız.',
    'Login failed': 'Değerli Tüketicimiz, hatalı e-posta/ şifre girişi yaptınız. Kampanyaya katıldığınız hesap bilgileri ile giriş yapmalısınız.',
    'Code is incorrect': 'Değerli Tüketicimiz, SMS kodunuzu hatalı girdiniz. Lütfen tekrar deneyin!',
    'usedIban': 'Bu Iban başka bir katılımcı tarafından kullanılmıştır.',
  },
  dictionary: {
    tr: {
      messages: {
        "ibanName": `Ç,Ğ,İ,Ö,Ş,Ü Türkçe karakterler kabul edilmemektedir. Lütfen sadece C, G, I, O, S, U şeklinde giriş sağlayınız`,
        "iban": "IBAN geçerli değil",
        "phoneNumber": "Telefon numarası geçerli değil",
      },
      names: {
        email: 'E-Posta',
        password: 'Şifre',
        iban: 'IBAN',
        firstname: 'Ad',
        firstName: 'Ad',
        lastname: 'Soyad',
        lastName: 'Soyad',
        phoneNumber: 'Telefon numarası',
        code: 'SMS Kodu',
      }
    }
  },
}
