<template>
  <div class="m-0 p-0">
    <ValidationProvider
      v-if="validation"
      :name="name"
      :rules="validation"
      v-slot="{ errors }"
    >
      <div class="form-group">
        <div class="input-group">
          <label
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="input-label"
          >
            {{ $t(label) }}
            <span v-if="validation.includes('required')">*</span>
          </label>
          <v-select
            v-model="localSelected"
            :reduce="option => option[valueKey]"
            :options="options"
            :disabled="disabled"
            :label="labelKey"
            :value="valueKey"
            :clearable="false"
            :searchable="false"
            :clear-search-on-select="true"
            :placeholder="localSelected ? '' : $t(placeholder)"
            class="w-100 style-chooser"
          >
          </v-select>
        </div>
        <div
          class="validation-error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <template v-else>
      <div
        class="selectInputWrapper"
        :style="`width:${width};`"
      >
        <select
          v-model="localSelected"
          required
          ref="select"
          :name="name"
          :id="field.fieldId"
        >
          <option
            value
            disabled
            :selected="!selectedValue"
            hidden
          ></option>
          <option
            v-for="option in options"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <label class="floating-label">{{ $t(placeholder) }}</label>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "DynamicSelectInput",
  data () {
    return {
      localSelected: this.selectedValue || "",
      options: [],
      disabled: false
    };
  },
  components: {
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    optionsApi: {
      type: String,
      required: true
    },
    responseOptionsKey: {
      type: String,
      default: ""
    },
    lazyOptions: {
      type: Boolean,
      default: false
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Something"
    },
    width: {
      type: String,
      default: "100%"
    },
    validation: {
      type: String,
      required: false,
      default: ""
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    showFlags: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    eventOnSelect: {
      type: String,
      default: ""
    }
  },
  watch: {
    localSelected (value) {
      if (['land', 'country'].includes(this.name)) {
        this.$eventHub.$emit('updatePLZonCountrySelection', value)
      }
      if (this.eventOnSelect) {
        const opt = this.options.find(o => o[this.valueKey] == value)
        this.$eventHub.$emit(this.eventOnSelect, opt)
      }
      this.$emit("component-updated", {
        selectedValue: value
      });
      this.$emit("input", value);
    }
  },
  async mounted () {
    if (this.lazyOptions) {
      this.$eventHub.$on("setSupportCaseTitles-event", this.setSupportCaseTitles)
      this.disabled = true
    } else {
      await axios.get(this.optionsApi).then(res => {
        if (res.data.response) {
          this.options = res.data[this.responseOptionsKey]
        }
      })
    }
  },
  methods: {
    setSupportCaseTitles(data) {
      this.localSelected = ""
      this.options = data.mostCommonSupportCases.map(item => ({
        label: item,
        value: item
      }))
      if (this.options) this.disabled = false
    }
  }
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #000;
  padding-left: 12px;
}

.style-chooser .vs__search {
  height: 46px;
  color: #000;
}

:deep() {
  --vs-selected-bg: #f3f3f3;
  --vs-dropdown-bg: #f3f3f3;
  --vs-dropdown-option-bg: #f3f3f3;
}
</style>
