<template>
  <div class="example-wrapper">
    <div class="item">
      <img
        :src="exampleImg(1)"
        :alt="$t(texts.correct)"
      />
      <p><img src="@/assets/images/svg/checkMark.svg" alt="Success" style="width: 15px; height: 15px;">{{ $t(texts.correct) }}</p>
    </div>
    <div class="item">
      <img
        :src="exampleImg(2)"
        :alt="$t(texts.notReadable)"
      />
      <p><img src="@/assets/images/svg/cross_red.svg" alt="Fail" style="width: 15px; height: 15px;">{{ $t(texts.notReadable) }}</p>
    </div>
    <div class="item">
      <img
        :src="exampleImg(3)"
        :alt="$t(texts.folded)"
      />
      <p><img src="@/assets/images/svg/cross_red.svg" alt="Fail" style="width: 15px; height: 15px;">{{ $t(texts.folded) }}</p>
    </div>
    <div class="item">
      <img
        :src="exampleImg(4)"
        :alt="$t(texts.cropped)"
      />
      <p><img src="@/assets/images/svg/cross_red.svg" alt="Fail" style="width: 15px; height: 15px;">{{ $t(texts.cropped) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReceiptExamples',
  props: {
    texts: {
      type: Object,
      default: () => ({
        correct: 'Correct',
        notReadable: 'Not readable',
        folded: 'Folded',
        cropped: 'Cropped'
      })
    },
    differentExamples: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    exampleImg (index) {
      if (this.differentExamples) return require(`@/assets/images/png/check_${index}_2.png`)
      return require(`@/assets/images/png/check_${index}.png`)
    }
  }
}

</script>

<style lang="scss" scoped>
.example-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 5px;
  border: 2px solid #A0A3BD;
  background: #FFF;
  padding: 5px;
  img {
    width: 80px;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    text-align: center;
    font-size: 10px;
    margin-bottom: 0;
  }
}
</style>
