<template>
  <div class="m-0 p-0">
    <ValidationProvider
      v-if="validation"
      :rules="localValidation"
      v-slot="{ errors }"
      :name="name"
    >
      <div class="input-group form-group">
        <label
          v-if="labelOnTop"
          :for="id"
          :style="labelOnTopStyle"
          class="input-label"
        >
          {{ $t(label) }}
          <span v-if="validation.includes('required')">*</span>
        </label>
        <div
          class="input-group-prepend iban-prepend"
          v-if="name == 'iban' && ibanPrepend != ''"
        >
          <div
            class="input-group-text"
            id="basic-addon1"
            style="height: 100%;"
          >
            <div>
              <country-flag
                :country="ibanPrepend"
                size="normal"
              />
            </div>
            <div class="prepend-text">
              {{ ibanPrepend }}
            </div>
          </div>
        </div>
        <textarea
          v-if="longText"
          :style="styles"
          :type="inputType"
          class="form-control"
          :class="{'is-invalid': errors.length, 'prepend-border': ibanPrepend, 'append-border': appendTooltip }"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :title="placeholder"
        ></textarea>
        <input
          v-else
          :style="styles"
          :type="inputType"
          class="form-control"
          :class="{'is-invalid': errors.length, 'prepend-border': ibanPrepend, 'append-border': appendTooltip }"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <div
          class="input-group-append tooltip-append"
          v-if="appendTooltip"
        >
          <span
            :id="`${name}_tip`"
            class="input-group-text"
            @mouseover="triggerTooltip"
            @mouseleave="triggerTooltip"
            @click="triggerTooltip"
          ><img
            src="@/assets/images/svg/info.svg"
            alt="Info"
          ></span>
        </div>
        <div v-if="appendTooltip">
          <b-tooltip
            ref="tooltip"
            :target="`${name}_tip`"
            interactive
            custom-class="custom-tooltip"
            placement="left"
            boundary="window"
          >
            <span v-html="toolTip.text">
            </span>
          </b-tooltip>
        </div>
        <label
          v-if="!labelOnTop && !noLabel"
          :for="id"
          class="floating-label"
          :class="{'slideUp': slideUp}"
        >
          {{ $t(label) }}
        </label>
        <div
          class="disclaimer"
          v-if="!!disclaimer"
        >
          {{ $t(disclaimer) }}
        </div>
        <div
          class="validation-error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <div
      class="m-0 p-0"
      v-else
    >
      <div class="form-group">
        <label
          v-if="labelOnTop"
          :style="labelOnTopStyle"
          class="input-label"
        >
          {{ $t(label) }}
          <span v-if="validation.includes('required')">*</span>
          <!-- <span v-else>(optional)</span> -->
        </label>
        <textarea
          v-if="longText"
          :style="styles"
          :type="inputType"
          class="form-control"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :title="placeholder"
          rows="6"
        ></textarea>
        <input
          v-else
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <label
          v-if="!labelOnTop && !noLabel"
          :for="id"
          class="floating-label"
          :class="{'slideUp': slideUp}"
        >
          {{ label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import CountryFlag from 'vue-country-flag'

export default {
  name: "TextInput",
  data () {
    return {
      passIcon: 'regular/eye',
      localValidation: ''
    }
  },
  components: {
    CountryFlag
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    text: {
      type: String,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    value: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    slideUp:Boolean,
    labelOnTop: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    isUpperCase: Boolean,
    toolTip: {
      type: Object,
      default: () => {
        return {}
      }
    },
    appendTooltip: {
      type: Boolean,
      default: false
    },
    ibanPrepend: {
      type: String,
      default: ""
    },
    disclaimer: {
      type: String,
      default: ""
    },
    longText: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    triggerTooltip () {
      this.$refs.tooltip.$emit("open");
    },
    updateValue (val) {
      let value = this.isUpperCase ? val.toUpperCase() : val;
      if(this.name === 'iban'){
        let first2chars = val.substring(0, 2).toUpperCase()
        //approved for GR only, requires iban:LOCALE in validation prop to function properly
        if(/^[a-zA-Z]+$/.test(first2chars) && this.ibanPrepend === first2chars){
          val = val.substring(2)
        }
        val = val.replaceAll(' ', '')
      }
      this.$emit("component-updated", {
        text: val,
        slideUp: !!val
      });
      this.$emit("input", val);
      this.$store.dispatch("setComponentStatus", {
        component: this.id,
        status: !!value
      });
    },
    updateValidation (val) {
      this.localValidation = val == 'de' ? 'required|plz:5' : 'required|plz:4'
    }
  },
  mounted () {
    this.localValidation = this.validation
    if (['plz', 'postal'].includes(this.name)) {
      this.$eventHub.$on('updatePLZonCountrySelection', this.updateValidation)
    }
  }
};
</script>

<style scoped>
.disclaimer {
  color: #4F4F4F;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}
</style>
