/* eslint-disable */

<template>
  <div>
    <router-view />
  </div>
</template>
<script>
const version = process.env.VUE_APP_VERSION;

export default {
  created () {
    // Remove console logs when in production
    if (process.env.NODE_ENV !== "development" && this.$route.query.version != "test") {
      console.log = function () { };
    }
    console.log("%cV" + version, "color:red; font-size:32px;");
  }
};
</script>
<style lang="scss">
@import "~@/assets/styles/_application.scss";

</style>
