<template>
  <span></span>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "query-string-to-state",
  props: {
    variables: Array
  },
  mounted () {
    this.variables.forEach(variable=>{
      this.setField({ field: variable.state, value: this.$route.query[variable.query] ? this.$route.query[variable.query] : variable.defaultValue })
    })
  },
  methods: {
    ...mapMutations(['setField'])
  }
};
</script>
