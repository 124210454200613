<template>
  <div
    :style="cssVars"
    :class="classes"
    :id="fieldId"
  >
    <div class="title">
      {{ $t(title) }}
    </div>
    <div
      class="wrapper input-group"
      :style="styles"
    >
      <span
        class="ref-number form-control"
        ref="target"
        id="copied-tooltip"
      >{{ value }}</span>
      <img
        src="@/assets/images/svg/clipboard.svg"
        alt="Copy"
        @click="copy"
        class="clipboard"
      >
    </div>
    <b-tooltip
      ref="tooltip"
      target="copied-tooltip"
      noninteractive
      disabled
    >
      {{ $t('Copied to clipboard') }}
    </b-tooltip>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "Clipboard",
  props: {
    fieldId: {
      type: [Number, String],
      required: true
    },
    text: {
      type: String,
      default: "Empty paragraph"
    },
    title: {
      type: String,
      default: "Empty paragraph"
    },
    variables: Object,
    disabledInput: {
      type: Boolean,
      default: false
    },
    colorSchema: {
      type: String,
      default: "#888888"
    },
    styles: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    value () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          const value = this.$store.state[variable];
          result = value;
        }
      }
      console.log("variable", result, this.text)
      return result;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  mounted () {
    const element = document.querySelector(`#${this.fieldId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  },
  methods: {
    copy () {
      var copyText = this.$refs.target;
      // copyText.innerText.select();
      // copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.innerText);
      this.$refs.tooltip.$emit('open')
      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 2000)
    },
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: var(--color);
  border-radius: 30px;
  height: 50px;
  padding: 5px 20px 5px 20px;
  align-items: center;
  // width: 50%;
  .ref-number {
    background: transparent;
    color: #FFF;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding: 5px 10px;
    .b-icon {
      font-size: 20px !important;
      color: #FFF;
    }
  }
}


.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--color);
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 11px;
    text-align: center;
  }
}
input {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  border: none;
  background: transparent;
  padding: 5px;
  &:disabled {
    background: transparent;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

#copied-tooltip {
  font-weight: 700;
  border: none;
  padding-left: 5px;
  padding-bottom: 0;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.clipboard {
  cursor: pointer;
  
  @media (max-width: 767px) {
    max-width: 15px;
  }
}

</style>
