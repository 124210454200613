<template>
  <div>
    <button type="button" class="btn btn-outline-primary w-100" @click="onPickFile" v-if="!image">
      {{ selectLabel }}
    </button>
    <button type="button" class="btn btn-outline-primary w-100" @click="removeFile" v-if="image">
      {{ removeLabel }}
    </button>
    <input
      type="file"
      ref="image"
      name="image"
      :accept="accept"
      @change="onFilePicked"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: File
    },
    accept: {
      type: String,
      default: '*'
    },
    selectLabel: {
      type: String,
      default: 'Select an image'
    },
    removeLabel: {
      type: String,
      default: 'Remove'
    }
  },

  data () {
    return {
      image: null
    }
  },

  watch: {
    value (value) {
      if (!value) {
        this.removeFile()
      }
    }
  },

  methods: {
    onPickFile () {
      this.$refs.image.click()
    },

    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files

      if (files && files[0]) {
        let filename = files[0].name

        if (filename && filename.lastIndexOf('.') <= 0) {
          return
        }

        this.image = files[0]
        this.$emit('input', files[0])
      }
    },

    removeFile () {
      this.image = null
      this.$refs.image.value = ''
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>
  input[type=file] {
    position: absolute;
    left: -99999px;
  }
</style>
