import Vue from "vue";
import updateProperty from "./updateProperty"
import actions from "./componentActions"
import showModal from "./showModal"
import cssVars from "./cssVars"
import scrollTo from './scrollTo';
import isTester from './isTester';

Vue.mixin(updateProperty)
Vue.mixin(actions)
Vue.mixin(showModal)
Vue.mixin(cssVars)
Vue.mixin(scrollTo);
Vue.mixin(isTester);

export default {
  updateProperty,
  showModal,
  scrollTo,
  isTester
}
