<template>
  <section
    :class="['section-container', {'row': !noRow}, classes, { 'disabled': sectionDisabled() }]"
    :data-section="sectionId"
    :style="cssVars"
    :id="sectionId"
  >
    <component-renderer
      v-for="field in fields"
      :key="field.id"
      :class="`col-lg-${getColumns(field)} component-wrapper`"
      :section-id="sectionId"
      :field="field"
      @component-updated="updated"
    />
  </section>
</template>

<script>
import componentRenderer from "@/components/component-renderer.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    componentRenderer
  },
  props: {
    sectionId: {
      type: [Number, String],
      required: false
    },
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object,
      required: false,
      default: () => {
        return {
          columns: 12,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0
        };
      }
    },
    noRow: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ""
    },
    requires: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['hasUnresolvedDependencies', 'currentSection']),
    dependencies () {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    sectionDisabled () {
      if (!this.dependencies) return false
      else return this.dependencies || this.currentSection != this.sectionId;
    },
    getColumns (field) {
      return field.properties && field.properties.grid
        ? field.properties.grid.columns
        : 12;
    },
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });
      // field.properties.text = properties.text;
      // updatedField.properties.text= "Hello"
      updatedField.properties = { ...updatedField.properties, ...properties };

      // console.log(
      //   "UPDATED:::",
      //   field,
      //   "properties",
      //   properties,
      //   "updatedField",
      //   updatedField
      // );
      this.$store.dispatch("setComponentStatus", {
        component: this.sectionId,
        status: true
      });
    }
  }
};
</script>

<style scoped lang="scss">

section.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.buttons-wrapper {
  justify-content: center;
  margin: auto;
  @media (max-width: 767px) {
    flex-direction: column-reverse !important;
  }
}

.container {
  margin-left: auto !important;
  margin-right: auto !important;

  @media (min-width: 576px) {
    max-width: 600px !important;
  }

  @media (min-width: 768px) {
    max-width: 991px !important;
  }

  @media (min-width: 991px) {
    max-width: 1024px !important;
  }

  @media (min-width: 1024px) {
    max-width: 1440px !important;
  }
}
.mobile-only, mobile-only-flex {
  display: none !important;
}
.desktop-none {
  display: none !important;
}
@media (max-width: 991px) {
  .desktop-only {
    display: none !important;
  }
  .desktop-none {
    display: initial !important;
  }
}
@media (max-width: 991px) {
  .mobile-none {
    display: none !important;
  }
  .mobile-only {
    display: grid !important;

    &-flex {
      display: flex !important;
    }
  }
}

.button-section {
  justify-content: space-between;
  margin: 40px auto 0 auto;
  padding-bottom: 40px;
  max-width: 1200px;
  @media (max-width: 1199px) {
    max-width: 71%;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    flex-direction: column;
  }
  @media (max-width: 425px) {
    margin: 35px auto 0 auto;
    padding-bottom: 30px;
  }
}
</style>
