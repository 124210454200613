<template>
  <div class="wrapper">
    <div class="top">
      <div
        class="desc"
        v-html="$t(texts.descText)"
      ></div>
      <div
        class="subDesc"
        v-html="$t(texts.descSubText)"
      ></div>
    </div>
    <div class="middle">
      <div class="text-center">
        <img
          class="img-fluid receipt-image"
          :src="receiptImage"
          alt="receipt example"
        >
      </div>
      <div>
        <ul class="wraper_punkt">
          <li
            class="media"
            :key="step.label"
            v-for="step in steps"
          >
            <div class="item">
              {{ $t(step.label) }}
            </div>
            <div class="media-body item_text">
              {{ $t(step.text) }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!hideExamples">
      <receipt-examples
        :different-examples="differentExamples"
      />
    </div>
  </div>
</template>

<script>
import ReceiptExamples from "./receipt-examples.vue";
export default {
  name: "ReceiptInformation",
  components: {
    ReceiptExamples
  },
  props: {
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    hideExamples: {
      type: Boolean,
      default: false
    },
    differentExamples: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    pullDown: false
  }),
  computed: {
    shouldDisplayFull () {
      return screen.width > 992 ? true : false
    },
    receiptImage () {
      if (this.imgSrc) {
        return require(`@/${this.imgSrc}`)
      }
      try {
        return require(`@/${this.$t('receipt-image')}`)
      } catch {
        return require("@/assets/images/png/receipt.png")
      }
    }
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: transparent;
  padding: 10px 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  .middle {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.wraper_punkt {
  margin-bottom: 15px;
  li {
    margin-bottom: 15px;
    font-size: 16px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    .item {
      width: 20px;
      height: 20px;
      display: inline-block;
      color: #4E4B66;
      border-radius: 100px;
      text-transform: uppercase;
      margin-right: 10px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
    .item_text {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.16px;
      text-align: left;
      color: var(--text-color-4);
      @media(max-width: 1024px){
        font-size: 14px;
      }
      @media (max-width: 668px) {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.receipt-image {
  max-width: 180px;
  @media (max-width: 991px) {
    max-width: 150px;
  }
}

.desc {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1D222A;
  text-align: left;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin: 10px 0;
    font-size: 16px;
    line-height: 20px;
  }
}
.subDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4E4B66;
  text-align: left;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
  }
}

</style>
