<template>
  <div>
    <div class="row">
      <div class="col-12 p-0">
        <barcodeScanner
          :texts="texts"
          :height="readerHeight"
          :width="readerWidth"
          :camera-time-out="cameraTimeOut"
          :max-product-count="maxProductCount"
          :endpoint="searchEndpoint"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import BarcodeScanner from "./BarcodeScanner.vue";
export default {
  name: "barcode-scanner",
  components: {
    BarcodeScanner
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.scannedProducts,
      status: (state) => state.searchStatus,
      barcode: (state) => state.productCode
    }),
  },
  watch: {
    '$store.state.scannedProducts' (val) {
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: val
      });
    }
  },
  props: {
    fieldId: {
      type: String,
      default: "barcodeScanner"
    },
    readerHeight: {
      type: Number,
      default: 500
    },
    readerWidth: {
      type: Number,
      default: 500
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    endpoint: {
      type: String,
      default: '/submitProducts'
    },
    successAction: {
      type: [Object, Array],
      default: () => {}
    },
    cameraTimeOut: {
      type: Number,
      default: 10000
    },
    maxProductCount: {
      type: Number,
      default: 1
    },
    searchEndpoint: {
      type: String,
      default: '/searchBarcode'
    }
  },
  methods: {
    ...mapMutations["setBarCode", "setSearchStatus", "setErrorActivate", "setProducts"],
    send () {
      const formData = {
        selectedProducts: this.list
      };

      this.$store.dispatch("sendProducts", { formData, endpoint: this.endpoint }).then((r) => {
        if (r.response) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
  },
  beforeDestroy () {
    this.$eventHub.$off("sendBarcodeProducts-event");
    // Set the error message to false
    // this.setErrorActivate(false);
    // this.setProducts([]);
    // this.setBarCode('');
    // this.setSearchStatus(null);
  },
  mounted () {
    this.$eventHub.$on("sendBarcodeProducts-event", this.send);
  }
}
</script>
