<template>
  <span></span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
  name: "RefreshToken",
  mounted () {
    this.refreshTempId().then(customerToken => {
      this.setCustomerToken(customerToken);
    })
  },
  methods: {
    ...mapMutations(['setCustomerToken']),
    ...mapActions(['refreshTempId'])
  }
};
</script>
