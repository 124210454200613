<template>
  <div
    :class="[{ 'row': !noRow }, classes, `col-lg-${grid.columns ? grid.columns : ''}`]"
    :style="{ ...styles, ...cssVars }"
    :id="fieldId"
  >
    <template v-for="field in fields">
      <componentRenderer
        :class="`col-lg-${field.properties.grid.columns} col-sm-${field.properties.grid.columns}`"
        :field="field"
        :key="field.id"
        @component-updated="updated"
      />
    </template>
  </div>
</template>
<script>
import componentRenderer from "@/components/component-renderer.vue";

export default {
  name: "EmptyBucket",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: {
      type: Array,
      required: false,
      default: () => []
    },
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    },
    errorImages: {
      type: Boolean,
      required: false,
      default: false
    },
    noRow: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: String,
      default: ""
    },
    timeoutRedirect: {
      type: String,
      default: "",
      required: false
    }
  },
  mounted() {
    if (this.timeoutRedirect) {
      setTimeout(() => {
        if (!this.isTester) {
          window.location.href = this.timeoutRedirect;
        }
      }, 3000);
    }
  },
  methods: {
    updated(field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$emit("component-updated", this.field, properties);
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  }
};
</script>
<style scoped lang="scss">
@media (min-width: 992px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

.form-top-wrapper {
  background: rgba(235, 243, 248, 0.7);
  border-radius: 40px;
  padding: 4vw 8vw;

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.form-wrapper {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 48px;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    padding: 20px;
  }
}

.btn-bucket {
  justify-content: center;
  column-gap: 20px;

  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: nowrap;
  }
}
</style>
